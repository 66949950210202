<script>
    import axios from 'axios';
    import MyButton from '@/components/ui/MyButton.vue'
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { MyButton },
        data: () => ({
            userProfile: {},
            swiper: {
                spaceBetween: 100,
                allowTouchMove: true,
                slidesPerView: 1.5,
                centeredSlides: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                breakpoints: {
/*                     640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    }, */
                    1024: {
                        spaceBetween: 20,
                        slidesPerView: 2.5,
                    },
                },
            },
            swiperData: [
                {
                    id: 1, 
                    desc: 'Torneos y <br> experiencias <br> a nivel nacional',
                    icon: 'world' 
                },
                {
                    id: 2, 
                    desc: 'Promociones <br> y descuentos <br> exclusivos',
                    icon:'tag' 
                },
                {
                    id: 3, 
                    desc: 'Complementos <br> de tus videojuegos <br> favoritos',
                    icon:'game' 
                },
                {
                    id: 4, 
                    desc: 'Puntos Zuggy',
                    icon:'zuggy' 
                }
            ]
        }),
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true);
            this.trackLog({ 
                action: 'Ingreso registro completado', 
                medio: 'Botón', 
                sectionId: 8, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="introduction-view pt-25">
        <div class="container">
            <h1 class="tx-24 tx-center mb-0">
                ¡Ya eres miembro <br> de nuestro Zuggy Club Gamers!
            </h1>
            
            <div class="tx-18 tx-center mb-10">
                Ahora podrás disfrutar de:
            </div>
            
            <!-- SWIPER -->
            <swiper class="swiper tx-18 tx-bold tx-center w-100pr mb-20 mt-17" :options="swiper" ref="swiper">
                <swiper-slide class="flex justify-center" v-for="(item, idx) in swiperData" :key="idx">
                    <div class="w-fit">
                        <div class="wrapper-icon grid place-items-center h-45 w-45 mb-5">
                            <img :src="require(`@/assets/images/views/welcome/icon-${item.icon}.png`)" alt="">
                        </div>
                        <p class="tx-18 tx-bold tx-center mb-0" v-html="item.desc">
                        </p>
                    </div>
                </swiper-slide>
            </swiper>

            <div class="max-w-100 mx-auto">
                <my-button class="mb-4" label="¡Comienza tu experiencia y crea tu perfil!" path="/crear-perfil" action="Ingreso a mi perfil" :sectionId="8" link/>
                <my-button action="Ingreso a arena zuggy" :sectionId="8" label="Navegar" path="/zona-premier" link/>
            </div>
        </div>
    </div>
</template>