<script>
    import axios from "axios"

    import MyButton from '@/components/ui/MyButton.vue'
    import CommentItem from '@/components/CommentItem.vue'
    export default {
        components: { MyButton, CommentItem },
        data: () => ({
            current: {},
            comments: [],
            fieldComment: false,
            comment: '',
            modal: false
        }),
        methods: {
            async getForum (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `detalle-foro?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&foro_id=` + this.$route.params.id);
                    this.current = res.data[0]
                    this.comments = res.data[0].comentarios.reverse()
                } catch (err) { console.log(err) }
            },
            handleAddComment(){
                this.fieldComment = true
            },
            async publishComment() {
                if (this.comment === '') return

                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `comentario-foro?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}&foro_id=${this.current.id}&comentario=${this.comment}`)
                    this.getForum()
                    
                    this.modal = true
                    this.comment = ''
                    this.fieldComment = false
                    console.log(res);
                } catch (err) { console.log(err) }
            }
        },
        computed: {
            getComments(){
                return this.current.comentarios.reverse()
                return this.current.comentarios.filter(comment => comment.activo === 1).reverse()
            }
        },
        mounted() {
            this.getForum();
        }
    }
</script>
<template>
    <div class="forum-detail-view">
        <div class="container">
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>{{ current.titulo }}</span>
            </div>

            <!-- COVER -->
            <img class="rounded-10 mb-5 mx-auto" :src="current.imagen" alt="">
            <!-- <div class="relative bg-center bg-cover bg-no-repeat h-74 w-100pr rounded-10 mb-5 mx-auto" :style="{ 'background-image': 'url(' + current.imagen + ')' }"></div> -->
            
            <!-- BODY -->
            <div class="box p-0">
                <div class="box-title py-2 px-4 tx-center tx-20 tx-bold rounded-t-10" v-html="current.sinapsis"></div>
                <div class="box-body forum-body tx-14 mb-6 p-9 rounded-b-10" v-html="current.descripcion"></div>
            </div>

            <div class="tx-12 tx-right opacity-07 mb-11" v-if="current.comentarios.length > 0">
                {{ current.comentarios.length }} {{ current.comentarios.length > 1 ? 'comentarios' : 'Comentario' }} 
            </div>

            <div class="relative" v-if="!fieldComment">
                <my-button class="mb-8" label="Añadir un comentario" @clicked="handleAddComment()"  :sectionId="2" action="Añadir comentario" :elementId="current.id"/>

                <!-- LAYER RESTRICT -->
                <!--<layer-restrict class="w-fit" :type="2" v-if="!$store.getters['profileCompleted']"/>-->
                <layer-restrict v-if="!$store.getters['isMember']"/>
            </div>

            <div class="mb-8" v-if="fieldComment">
                <div class="tx-14 tx-right opacity-08 mb-2">
                    {{ comment.length }} / 250
                </div>
                <div class="form-group mb-1">
                    <label class="form-label form-label--cg" for="">Deja tu comentario:</label>
                    <textarea class="form-input form-input--cg resize-none" rows="3" type="text" maxlength="250" v-model="comment"></textarea>
                </div>

                <my-button classBtn="btn--cg-sm ml-auto" label="Publicar" @clicked="publishComment()" :sectionId="2" action="Publicar comentario" :elementId="current.id"/>
            </div>

            <!-- COMMENTS -->
            <div class="tx-14 tx-center opacity-07 mt-15" v-if="comments.length <= 0">
                Se el primeo en comentar
            </div>
            <div v-else>
                <comment-item :data="i" v-for="(i, idx) in comments" :key="idx"/>
            </div>
        </div>

        <!-- MODAL ALERTS -->
        <transition name="fade">
            <div class="modal flex align-center z-20" v-if="modal">
                <div class="modal-lightbox w-100pr max-w-100 py-10 px-4 bg-center bg-cover bg-no-repeat rounded-0">
                    <div class="tx-black">
                        <p class="tx-20 tx-center tx-medium mb-2">
                            ¡Gracias por sumarte a la plática!
                        </p>
                        <p class="tx-16 tx-center mb-9">
                            Tu comentario está próximo a publicarse
                        </p>
                        <button class="btn btn--success mx-auto flex" @click="modal = false">
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
    .box-title{
        background: linear-gradient(to right, #8708C6, #3C0778);
    }

    .box-body{
        background-color: rgba($color: #fff, $alpha: 0.3);
    }
</style>