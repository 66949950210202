<script>
    import axios from "axios"

    import MyButton from '@/components/ui/MyButton.vue'
    import ControlsBackAndHome from '@/components/layout/ControlsBackAndHome.vue'
    import DropDown from '@/components/ui/DropDown.vue'
    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import ModalIke from '@/components/ModalIke.vue'
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { MyButton, ControlsBackAndHome, DropDown, HeaderComponent, ModalIke },
        data: () => ({
            modalForm: false,
            questions: [],
            questionsOdd: [],
            questionsEven: [],
            ikeMembership: null
        }),
        methods: {
            async getQuestions() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `preguntas-frecuentes?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`)
                    this.questions = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err); }
            },
            async ikeValidation() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `ike-vigencia?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}`)
                    this.ikeMembership = res.data.error
                    console.log(res.data);
                } catch (err) { console.log(err); }
            },
            handleModalForm(){
                this.ikeValidation()
                console.log("ikeValidation " + this.ikeMembership)
                if (this.ikeMembership === 1) { 
                    this.modalForm = !this.modalForm 
                    return
                }

                sdkclaro.getInstance().getTopic("EXECUTE_Minimal_View", {
                    typeOperation: "openUrl",
                    parameters: "tel:+525590633558",
                });

                //window.open('tel:+525636374542')   
            },
            mailTo(){
                //document.location.href = "mailto:soporte@zuggyclubgamers.com";  
                sdkclaro.getInstance().getTopic("EXECUTE_Minimal_View", {
                    typeOperation: "openUrl",
                    parameters: "mailto:soporte@zuggyclubgamers.com",
                }); 
            },
            AvisodeprivacidadTo(){
                document.location.download = "mailto:soporte@zuggyclubgamers.com";   
            },
            termsTo(path){
                this.$router.push({ path: path })   
            },
            avisoTo(path){
                this.$router.push({ path: path })   
            },
            politicaTo(path){
                this.$router.push({ path: path })   
            },
            forosTo(path){
                this.$router.push({ path: path })   
            },
        },
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true)
            this.getQuestions()
            this.ikeValidation()
            this.trackLog({ 
                action: 'Ingreso a Preguntas frecuentes', 
                medio: 'Botón', 
                sectionId: 4, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="help-view py-15">
        <div class="container">
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>PREGUNTAS FRECUENTES</span>
            </div>
            <div class="grid md:grid-cols-2 gap-y-2 gap-x-4">   
                <drop-down :question="item.pregunta" :answer="item.respuesta" v-for="(item, idx) in questions" :key="idx"/>
            </div>
        </div>

        <transition name="fade">
            <modal-ike v-if="modalForm" @close="handleModalForm()"></modal-ike>
        </transition>
    </div>
</template>
<style lang="scss" scoped>
    .grid-faqs{
        .dropdown{
            width: 50%;
        }
    }
</style>