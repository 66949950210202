<script>
    export default {
        data() {
            return {
                url: window.location.href,
                urlCopied: false
            }
        },
        methods: {
            copyUrl(){
                var textArea = document.createElement("textarea");
                textArea.value = this.url;

                textArea.style.top = "0";
                textArea.style.left = "0";
                textArea.style.position = "fixed";
                textArea.style.opacity = "0";

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    console.log('Fallback: Copying text command was ' + msg);
                    alert('Link copiado en el portapapeles');

                    textArea.remove();
                } catch (err) {
                    console.error('Fallback: Oops, unable to copy', err);
                }
            },
        }
    }
</script>

<template>
    <div class="share-component">
        <!-- <p class="mb-2 tx-right tx-medium">
            Compartir
        </p>
        <div class="flex align-center md:ml-auto">
            <a class="icon-share share-fbk block mr-3 transition-200" :href="'https://www.facebook.com/sharer/sharer.php?u=' + url" target="_blank">
                <img width="20px" src="@/assets/images/layout/icon-fbk.svg" alt="">
            </a>
            <a class="icon-share share-wa block mr-3 transition-200" :href="'https://wa.me/?text=' + url" target="_blank">
                <img width="18px" src="@/assets/images/layout/icon-wa.svg" alt="">
            </a>
            <a class="icon-share share-cp block transition-200" @click="copyUrl()">
                <img width="18px" src="@/assets/images/layout/icon-link.svg" alt="">
            </a>
        </div> -->
    </div>
</template>
