<script>
    import axios from 'axios'

    import ControlsBackAndHome from '@/components/layout/ControlsBackAndHome.vue'
    import ShoppingHistoryItem from '@/components/ShoppingHistoryItem.vue'
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { ControlsBackAndHome, ShoppingHistoryItem },
        data: () => ({
            testhistoryProducts: [
                {
                    titulo: 'juego de algo',
                    nombre: 'juego de algo',
                    fecha_compra: '01.01.1970',
                    producto_id: 210
                },
                {
                    titulo: 'juego de algo',
                    nombre: 'juego de algo',
                    fecha_compra: '01.01.1970',
                    producto_id: 210
                },{
                    titulo: 'juego de algo',
                    nombre: 'juego de algo',
                    fecha_compra: '01.01.1970',
                    producto_id: 210
                },{
                    titulo: 'juego de algo',
                    nombre: 'juego de algo',
                    fecha_compra: '01.01.1970',
                    producto_id: 210
                }
            ],
            historyProducts: [],
            userProfile: {},

        }),
        methods: {
            async getProfile() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `perfilamiento?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}`)
                    this.userProfile = res.data[0];

                    var hoy = new Date();
                    var cumpleanos = new Date(res.data[0].fecha_de_nacimiento);
                    this.edad = hoy.getFullYear() - cumpleanos.getFullYear();

                } catch (err) { console.log(err) }
            },
            async getShoppingHistory(){
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `historial-compras?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}`)
                    this.historyProducts = res.data.sort((a, b) => {
                        return new Date(a.fecha_compra) - new Date(b.fecha_compra)
                    }).reverse();
                    console.log(res);
                } catch (err) {
                    console.log(err);
                }
            } 
        },
        mounted() {
            sdkclaro.getInstance().switchGoBackButton(true);
            this.getShoppingHistory();
            this.getProfile();
            this.trackLog({ 
                action: 'Ingreso a historial de compras', 
                medio: 'Botón', 
                sectionId: 7, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="shopping-history-view">
        <div class="container">
            <!-- LIST -->
            <div class="tx-14 tx-center opacity-07 mt-15" v-if="historyProducts.length <= 0">
                Aún no has realizado tu primera compra
            </div>
            <div v-else>
                <shopping-history-item class="mb-5" v-for="(item, idx) in historyProducts" :key="idx" :data="item"/>
            </div>
        </div>
    </div>
</template>