<script>
    import axios from 'axios';

    export default {
        data: () => ({
            render: false,
            swiperTop: {
                spaceBetween: 40,
                allowTouchMove: true,
                slidesPerView: 1.3,
                centeredSlides: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
            },
            swiperDataTop: [
                { id: 1, title: 'Copa Mundial Fortnite 2023', description: '¡Alista tus armas y prepárate para el evento más grande del año Acceso exclusivo para miembros!', banner: 'test-banner-1', url: '' },
                { id: 2, title: 'Copa Mundial Fortnite 2023', description: '¡Alista tus armas y prepárate para el evento más grande del año Acceso exclusivo para miembros!', banner: 'test-banner-3', url: '' },
                { id: 3, title: 'Campeonato Mundial de League of Legends', description: 'Tenemos al accesos exclusivos para el torneo del MOBA más esperado del año', banner: 'test-banner-2', url: '' },
            ],
            videosData: [
                { id: 1, banner: 'test-video-1.png', route: '' },
                { id: 2, banner: 'test-video-2.png', route: '' },
                { id: 3, banner: 'test-video-3.png', route: '' },
            ],
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            showCalendar: false,
            dataEvents: [],
            nextEvent: null,
            nextEventDate: null,
            myEvents: {},
            featuredVideos:[],
        }),
        methods: {
            changeSlide(index) {
                this.$refs.swiperTop.$swiper.slideTo(index, 400);
            },
            nextSlide() {
                this.$refs.swiperTop.$swiper.slideNext();
            },
            prevSlide() {
                this.$refs.swiperTop.$swiper.slidePrev();
            },
            async getEvents (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `proximos-eventos?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.dataEvents = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            async getMyEvents (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `mis-eventos?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=` + this.$store.getters['userId']);

                    if (res.data.length <= 0) {
                        this.nextEvent = null
                        this.nextEventDate = null
                        return
                    }

                    const sortByDate = res.data.sort((a,b) => new Date(a.fecha) - new Date(b.fecha));

                    /* ORDER EVENTS BY MONTH */
                    this.myEvents = res.data.reduce((value, obj) => {
                        let date = new Date(obj.fecha).getMonth();
                    
                        value[date] = (value[date] || []).concat(obj);
                        return value;
                    }, {});
                    
                    /* NEXT EVENT */
                    this.nextEvent = sortByDate[0];
                    this.nextEventDate = new Date(sortByDate[0].fecha);

                } catch (err) { console.log(err) }
            },
            async getFeaturedVideos (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `videos-destacados?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.featuredVideos = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            async deleteEvent(eventId){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `baja-evento?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}&evento_id=${eventId}`);
                    this.getMyEvents()
                    this.toggleCalendar()
                } catch (err) { console.log(err) }
            },
            goToEvent(path){
                this.trackLog({ 
                    action: 'Click próximos eventos', 
                    medio: 'Banner', 
                    sectionId: 1, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
            goToVideo(path){
                this.trackLog({ 
                    action: 'Click Video', 
                    medio: 'Banner', 
                    sectionId: 2, 
                    elementId: null, 
                })
                this.$router.push({ path: path })
            },
            goToNextEvent(path){
                this.trackLog({ 
                    action: 'Click mi siguiente evento', 
                    medio: 'Banner', 
                    sectionId: 1, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
            goToFeaturedVideo(url){
                this.trackLog({ 
                    action: 'Click videos destacados', 
                    medio: 'Banner', 
                    sectionId: 1, 
                    elementId: null, 
                })

                window.open(url, '_blank');
            },
            toggleCalendar(){ this.showCalendar = !this.showCalendar },
            beforeEnter(el){ el.style.height = 0 },
            enter(el){ el.style.height = el.scrollHeight + 'px' },
            beforeLeave(el){ el.style.height = el.scrollHeight + 'px' },
            leave(el){ el.style.height = 0 },
        },
        mounted(){
            this.getEvents();
            this.getMyEvents();
            this.getFeaturedVideos();

            setTimeout(() => this.render = true, 0);

            this.trackLog({ 
                action: 'Ingreso a arena zuggy', 
                medio: 'Botón', 
                sectionId: 1, 
                elementId: null, 
            })
        }
    }
</script>

<template>
    
    <div class="zuggy-ground-view relative">
        <div class="relative">
            <div class="container">
                <p>
                    Próximos Eventos
                </p>
            </div>
            <!-- EVENTS SWIPER -->
            <swiper class="swiper mb-6" :options="swiperTop" ref="swiperTop" v-if="render">
                <swiper-slide v-for="(item, idx) in dataEvents" :key="idx">
                    <div class="item-event flex align-end w-100pr h-40 relative bg-center bg-cover bg-no-repeat rounded-10 border-1 border-white p-4 z-0 overflow-hidden" :style="{ 'background-image': 'url(' + item.imagen + ')' }" @click="goToEvent(`/evento/${item.id}`)">
                        <div class="max-w-75pr">
                            <h3 class="tx-14 mb-1 tx-medium max-h-9 overflow-hidden">
                                {{ item.titulo }}
                            </h3>
                            <p class="tx-12 mb-0 max-h-8 overflow-hidden">
                                {{ item.sinapsis }}
                            </p>
                        </div>
                        <!-- <img class="decore-shine absolute b-0 -mb-2" src="@/assets/images/layout/shine-btn.png" alt=""> -->
                    </div>
                </swiper-slide>
            </swiper>
        
            <div class="container relative">   
                <template v-if="nextEvent === null">
                    <p class="mb-2">
                        Calendario Interactivo
                    </p>
                    <div class="flex relative mb-6">
                        <img src="@/assets/images/views/zuggy_ground/add-event.png" alt="">
                        <img class="decore-shine absolute r-0 l-0 b-0" style="margin-bottom: -6px;" src="@/assets/images/layout/shine-banner.png" alt="">
                    </div>
                </template>
                <div class="mb-6" v-else>
                    <p class="mb-2">
                        Tu próximo evento: <br> {{ nextEvent.titulo }}
                    </p>
                    
                    <!-- NEXT EVENT -->
                    <div @click="toggleCalendar()">
                        <div class="next-event relative flex align-end border-1 bg-center bg-cover bg-no-repeat border-white h-41 w-100pr rounded-10 z-0 overflow-hidden p-3" :style="{ 'background-image': 'url(' + nextEvent.imagen + ')' }">
                            <span>
                                {{ nextEventDate.getMonth() + 1 }} - 
                                {{ nextEventDate.getDate() }} - 
                                {{ nextEventDate.getHours() }}
                                :
                                {{ (nextEventDate.getMinutes() < 10 ? '0' : '') + nextEventDate.getMinutes() }}
                            </span>
                        </div>
                        <img class="decore-shine r-0 l-0 b-0 -mt-6" src="@/assets/images/layout/shine-banner.png" alt="">
                    </div>

                    <!-- LIST EVENTS -->
                    <transition
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @before-leave="beforeLeave"
                        @leave="leave"
                    >
                        <div class="mb-5 overflow-hidden transition-400" v-if="showCalendar">
                            <div class="mb-6" v-for="(val, month) in myEvents" :key="month">
                                <p class="tx-medium mb-1">
                                    {{ months[month] }}
                                </p>
                                <ul class="tx-14 bullet-none pl-0 mt-0">
                                    <li class="flex justify-between align-center py-2 border-b-1 border-white px-4" v-for="(event, idx) in val" :key="idx">
                                        <span class="tx-underline" @click="goToNextEvent(`/evento/${event.id}`)">
                                            {{ event.titulo }}
                                        </span>
                                        <div class="flex align-center pl-4">
                                            <span>
                                                {{ new Date(event.fecha).getDate() }}
                                            </span>
                                            <div class="h-10 w-10 grid place-items-center ml-2" @click="deleteEvent(event.evento_id)">
                                                <img class="w-5" src="@/assets/images/layout/icon-trash.png" alt="">
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </transition>
                </div>    
            </div>
            <layer-restrict v-if="!$store.getters['isMember']"/>
        </div>
        <div class="container">
           
            <!-- VIDEOS -->
            <p class="mb-2">
                Videos Destacados
            </p>
            
            <div class="grid grid-cols-3 gap-x-4 gap-y-10 pb-15 position-relative">
               
                <a class="h-36 relative bg-center bg-cover bg-no-repeat rounded-10 border-2" :style="{ 'background-image': 'url(' + video.banner + ')' }" v-for="(video, idx) in featuredVideos" :key="idx" @click="goToVideo(`/video/${video.id}`)">
                    <div class="flex flex-column justify-center align-center absolute b-0 r-0 l-0 mx-auto -mb-8">
                        <img class="w-12" src="@/assets/images/views/zuggy_ground/icon-plus.svg" alt="">
                        <img class="decore-shine -mt-2" src="@/assets/images/views/zuggy_ground/shine-btn-plus.png" alt="">
                    </div>
                </a>
            </div>
        </div>
        <!---->
    </div>
</template>
<style lang="scss" scoped>

    .swiper{
        padding-top: 18px!important;
        padding-bottom: 18px!important;
    }

    .swiper-slide{
        transition: transform 400ms ease;
    }
    .swiper-slide-active{
        transform: scale(1.2);
    }

    .item-event::after,
    .next-event::after{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        //background-image: linear-gradient(rgba(#000, 0), rgba(#000, 0.2), rgba(#000, 1));
        z-index: -1;
    }
</style>