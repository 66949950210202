<script>
    import axios from 'axios';
    
    import ModalVideoPlayer from '@/components/ModalVideoPlayer.vue';
    import MyButton from '@/components/ui/MyButton.vue';
    import * as sdkclaro from "@claro/sdkclaro";
    
    export default {
        components: { ModalVideoPlayer, MyButton },

        data: () => ({
            modalVideo: false,
            showVideo: '',
            swiper: {
                spaceBetween: 16,
                allowTouchMove: true,
                centeredSlides: false,
                slidesPerView: 2.1,
                slidesPerView: 1.5,
                breakpoints: {
                    640: {
                        slidesPerView: 3,
                        autoplay: {
                            delay: 3000,
                            disableOnInteraction: false,
                        },
                    },
                    768: {
                        slidesPerView: 5,
                    },
                },
            },
            swiperData: [
                {
                    id: 1, 
                    desc: 'Torneos y <br> experiencias <br> a nivel nacional',
                    icon:'world' 
                },
                {
                    id: 2, 
                    desc: 'Promociones <br> y descuentos <br> exclusivos',
                    icon:'tag' 
                },
                {
                    id: 3, 
                    desc: 'Complementos <br> de tus videojuegos <br> favoritos',
                    icon:'game' 
                },
                {
                    id: 4, 
                    desc: 'Puntos <br> zuggy',
                    icon:'game' 
                },
                {
                    id: 5, 
                    desc: 'Y MUCHO <br> MÁS',
                    icon:'game' 
                }
            ]
        }),
        methods: {
            async validateProfileClaroPay(){
                console.log('validateProfileClaroPay welcome')

                if(this.$store.getters['isMember']){
                    this.$router.replace({ path: '/zona-premier' })
                }
            },
            changeSlide(index) {
                this.$refs.swiper.$swiper.slideTo(index, 400);
            },
            nextSlide() {
                this.$refs.swiper.$swiper.slideNext();
            },
            prevSlide() {
                this.$refs.swiper.$swiper.slidePrev();
            },
            closeModal() {
                this.modal = false;
                this.trackLog({ 
                    action: 'Video visto', 
                    medio: 'Botón', 
                    sectionId: 1, 
                    elementId: null, 
                })
            },
            async getShowVideo (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `welcome-video?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=` + localStorage.getItem('user-paypal-id'));
                    this.current = res.data

                    if(this.current.video == 1){
                        this.showVideo = true
                    } else {
                        this.showVideo = false
                    }

                } catch (err) { console.log(err) }
            },
            btnClick(btnAction, path){
                this.trackLog({ 
                    action: btnAction, 
                    medio: 'Botón', 
                    sectionId: 8, 
                    elementId: null, 
                })

                this.$router.push({ path: path });
            },
            handleGetMembership(){
                let path;
                console.log(this.$store.getters['logeddIn']);
                if(this.$store.getters['logeddIn']){
                    path = '/comprar-membresia'
                } else {    
                    path = '/login'
                }

                this.btnClick('Comprar Membresía', path)
            },
            toggleVideo(){
                this.modalVideo = !this.modalVideo
                window.localStorage.setItem('zuggy-video-de-bienvenida', true)
            }
        },
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true);
            this.validateProfileClaroPay();
            /* this.getShowVideo(); */

            this.trackLog({ 
                action: 'Ingreso a bienvenida', 
                medio: 'Botón', 
                sectionId: 8, 
                elementId: null, 
            })
            if (!window.localStorage.getItem('zuggy-video-de-bienvenida')) {
                this.modalVideo = true
            }
        }
    }
</script>
<template>
    <div class="welcome-view pt-30 pb-15">

        <img class="block lg:absolute t-0 l-0 w-30 xxl:w-45 mx-auto lg:m-10 xxl:m-20" src="@/assets/images/layout/logo.png" alt="">
        <div class="container">
            <img class="flex mx-auto mb-7" src="@/assets/images/views/welcome/lettering.png" alt="">

            <div class="box box--white rounded-14 px-4 py-10 sm:p-10">
                <div class="sm:flex md:grid-cols-3 align-center w-fit justify-center gap-2 mx-auto mb-5">
                    <div class="mb-5 w-66" @click="btnClick('Ya soy socio', '/login')" v-if="!$store.getters['logeddIn']">
                        <img src="@/assets/images/views/welcome/btn-im-member.png" alt="">
                    </div>
                    <div class="mb-5" @click="handleGetMembership()" v-if="!$store.getters['isMember']">
                        <img src="@/assets/images/views/welcome/btn-membership.png" alt="">
                    </div>
                    <div class="mb-5" @click="btnClick('Ingreso a arena zuggy', '/zona-premier')">
                        <img src="@/assets/images/views/welcome/btn-navigate.png" alt="">
                    </div>
                </div>
                <p class="tx-16 tx-semibold tx-center tx-purple">
                    Con tu membresía mensual podrás obtener <br> cientos de beneficios como:
                </p>
                
                <!-- SWIPER -->
                <swiper class="swiper tx-18 tx-center w-100pr" :options="swiper" ref="swiper">
                    <swiper-slide class="flex justify-center" v-for="(item, idx) in swiperData" :key="idx">
                        <div class="w-fit">
                            <div class="wrapper-icon flex align-center h-33 w-33 mb-5 mx-auto">
                                <img class="w-100pr" :src="require(`@/assets/images/views/welcome/icon-${item.id}.png`)" alt="">
                            </div>
                            <p class="tx-12 tx-purple-2 tx-center tx-uppercase mb-0" v-html="item.desc">
                            </p>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>

        <!-- MODAL VIDEO -->
        <!-- <transition name="fade">
            <modal-video-player @close="toggleVideo()" v-if="modalVideo"/>
        </transition> -->
    </div>
</template>

<style lang="scss" scoped>
    .d-none {
        display: none;
    }

    .box--white{
        background: rgba($color: #fff, $alpha: 0.8);
    }
</style>