<script>
    import axios from "axios"
    import MyButton from '@/components/ui/MyButton.vue';

    import { useVuelidate } from '@vuelidate/core'
    import { required, email } from '@vuelidate/validators'
    import ModalAlert from '../ModalAlert.vue';

    export default {
        components: { MyButton, ModalAlert },
        setup () {
            return { 
                v$: useVuelidate(),
            }
        },
        data: () => ({
            userEmail: '',
            loading: false,
            modal: {
                state: false,
                description: '',
                type : ''
            },
            passwordRecover: 0,
        }),
        validations: () => ({
            userEmail: { required, email },
        }),
        computed: {
            userRecover () {
                return {
                    "correo_electronico"  : this.userEmail,
                }
            },
        },
        methods: {
            async submitFormRecover(){
                const result = await this.v$.$validate()
                if (!result) return
                
                this.loading = true
                axios.post(process.env.VUE_APP_SERVICE_URL  + `recuperar?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`, this.userRecover).then(res => {
                    console.log(res);
                    this.loading = false;
                    if(res.data.error === 1){
                        this.modal = {
                            state: true,
                            description: 'Correo electrónico no encontrado <br><br> <span class="tx-16 tx-regular">Verifique que el correo electrónico sea correcto.</span>',
                            type: 'danger',
                        }
                        this.passwordRecover = 1
                        return
                    }
                    
                    this.modal = {
                        state: true,
                        description: '¡Muchas gracias! <br><br> <span class="tx-16 tx-regular">Se ha enviado su contraseña a su correo electrónico.</span>',
                        type: 'success',
                    }
                }).catch(error => {
                    this.loading = false;
                    this.modal = {
                        state: true,
                        description: 'Ha ocurrido un error <br><br> <span class="tx-16 tx-regular">favor de intentarlo de nuevo</span>',
                        type: 'error',
                    }
                    console.log(error); 
                })
            },
            handleAccept(){
                if (this.passwordRecover === 1) {
                    this.modal.state = false
                    return
                }
                window.location.reload();
            }
        }
    }
</script>

<template>
    <div>
        <div>
            <div class="form-group mb-4">
                <label class="form-label form-label--cg tx-10" for="">Correo electrónico</label>
                <input class="form-input form-input--cg" type="text" placeholder="Email" maxlength="50" v-model.trim="userEmail" pattern="[a-z]{1,50}">
            </div>
            <div style="color: red;" class="tx-error tx-14 opacity-1 tx-center mb-4 -mt-3" v-if="v$.userEmail.$error">
                Favor de escribir un correo electrónico válido
            </div>
            <div class="btn btn--cg-1 tx-bold flex btn--white max-w-38 mx-auto mb-3" @click="submitFormRecover()">
                Enviar
            </div>
        </div>
        
        <!-- LOADER -->
        <div class="overlay fixed grid place-items-center t-0 l-0 w-100pr h-100pr z-10" v-if="loading">
            <div class="loader-spinner"></div>
        </div>

        <!-- MODAL -->
        <transition name="fade">
            <modal-alert v-if="modal.state" @close="handleAccept()">
                <div class="p-4">
                    <p class="tx-22 tx-bold tx-center mb-9" v-html="modal.description"></p>
                    <button class="btn mx-auto flex" :class="modal.type === 'success' ? 'btn--success' : 'btn--danger'" @click="handleAccept(modal.type)">
                        Aceptar
                    </button>
                </div>
            </modal-alert>
        </transition>
    </div>
</template>
<style lang="scss" scoped>  
    .overlay{
        background: rgba($color: #000, $alpha: 0.4)
    }

    .tx-error{
        color: rgb(255, 69, 69)!important;
    }

    .form-input--cg,
    .form-label--cg{
        font-size: 14px!important;
    }

    .form-label--cg{
        padding: 5px 12px;
    }

    .form-input--cg{
        height: 30px;

        &::placeholder{
            font-size: 14px;
        }
    }
</style>