<script>
    import axios from "axios"

    import MyButton from '@/components/ui/MyButton.vue'
    import ControlsBackAndHome from '@/components/layout/ControlsBackAndHome.vue'
    import DropDown from '@/components/ui/DropDown.vue'
    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import ModalIke from '@/components/ModalIke.vue'
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { MyButton, ControlsBackAndHome, DropDown, HeaderComponent, ModalIke },
        data: () => ({
            cambiosTerminos: false,
            modalForm: false,
            questions: [],
            ikeMembership: null,
            modalDesc: {
                state: false,
                title: 'Términos y Condiciones',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore consequuntur impedit ea iusto ad deleniti unde eius, laudantium aspernatur neque odio quos amet saepe animi debitis assumenda nemo. Doloribus, quae.'
            }
        }),
        methods: {
            async getQuestions() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `preguntas-frecuentes?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`)
                    this.questions = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err); }
            },
            async ikeValidation() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `ike-vigencia?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}`)
                    this.ikeMembership = res.data.error
                    console.log(res.data);
                } catch (err) { console.log(err); }
            },
            handleModalForm(){
                this.ikeValidation()
                console.log("ikeValidation " + this.ikeMembership)
                if (this.ikeMembership === 1) { 
                    this.modalForm = !this.modalForm 
                    return
                }

                sdkclaro.getInstance().getTopic("EXECUTE_Minimal_View", {
                    typeOperation: "openUrl",
                    parameters: "tel:+525590633558",
                });

                //window.open('tel:+525636374542')   
            },
            mailTo(){
                document.location.href = "mailto:soporte@zuggyclubgamers.com";  
               /*  sdkclaro.getInstance().getTopic("EXECUTE_Minimal_View", {
                    typeOperation: "openUrl",
                    parameters: "mailto:soporte@zuggyclubgamers.com",
                });  */
            },
            AvisodeprivacidadTo(){
                document.location.download = "mailto:soporte@zuggyclubgamers.com";   
            },
            async handleModalDesc(title, desc){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + desc + `?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}` );
                    this.modalDesc.desc = res.data.descripcion
                    this.modalDesc.title = title
                    this.modalDesc.state = true

                    console.log(this.modalDesc);
                } catch (err) { console.log(err) }
            },
        },
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true)
            this.getQuestions()
            this.ikeValidation()
            this.trackLog({ 
                action: 'Ingreso a asistencia', 
                medio: 'Botón', 
                sectionId: 4, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="help-view">
        <div class="container pt-10 md:pt-20">

            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>Asistencia y Políticas</span>
            </div>

            <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 gap-5 mb-5 w-fit mx-auto">
<!--                 <div class="cursor-pointer relative">
                    <img src="@/assets/images/views/help/suport.png" alt="" @click="handleModalForm()">
                    <layer-restrict v-if="!$store.getters['isMember']"/>
                </div> -->
                <div class="cursor-pointer" @click="handleModalDesc('Términos y Condiciones', 'terminos')">
                    <img src="@/assets/images/views/help/terms.png" alt="">
                </div>
                <div class="cursor-pointer" @click="handleModalDesc('Aviso de privacidad', 'aviso')">
                    <img src="@/assets/images/views/help/privacy.png" alt="">
                </div>
                <div class="cursor-pointer" @click="handleModalDesc('Política de reembolso de códigos digitales', 'politicas')">
                    <img src="@/assets/images/views/help/refounds.png" alt="">
                </div>
                <div class="cursor-pointer" @click="handleModalDesc('Políticas de foros', 'foros')">
                    <img src="@/assets/images/views/help/forums.png" alt="">
                </div>
            </div>
            <img class="btn-email block mx-auto cursor-pointer max-w-75 mb-15" src="@/assets/images/views/help/mail.png" alt="" @click="mailTo()">
        </div>

        <transition name="fade">
            <div class="modal flex align-baseline z-10 pt-20 z-25" v-if="modalDesc.state">
                <div class="modal-lightbox lightbox-ike w-100pr py-10 px-4 md:px-15 rounded-20">
                    <div class="modal-close -mt-6 -mr-6" @click="modalDesc.state = false">
                        <img src="@/assets/images/layout/icon-close.png" alt="">
                    </div>
                    <div class="title h-10 tx-center tx-14 tx-bold tx-uppercase grid align-center mb-5">
                        {{ modalDesc.title }}
                    </div>
                    <div class="desc tx-14 md:tx-16 overflow-auto pr-3" v-html="modalDesc.desc"></div>
                </div>
            </div> 
        </transition>

        <transition name="fade">
            <modal-ike v-if="modalForm" @close="handleModalForm()"></modal-ike>
        </transition>
    </div>
</template>
<style lang="scss" scoped>
    .modal{
        background-color: rgba($color: #000000, $alpha: 0);

        .title{
            background-color: #3C0778;
        }

        .desc{
            max-height: 55vh;
        }

        &-lightbox{
            width: 100%;
            max-width: 958px;
            background: rgba(0, 0, 0, 0.18);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(13.7px);
            -webkit-backdrop-filter: blur(13.7px);
        }
    }

    .btn-email{
        width: 53%;
    }
</style>