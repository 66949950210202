<script>
    import axios from "axios"
    import MyButton from '@/components/ui/MyButton.vue';

    import { useVuelidate } from '@vuelidate/core'
    import { required, email, minLength, maxLength } from '@vuelidate/validators'
    import ModalAlert from '../ModalAlert.vue';

    export default {
        components: { MyButton, ModalAlert },
        setup () {
            return { v$: useVuelidate() }
        },
        data: () => ({
            userName: '',
            userEmail: '',
            userPhoneNumber: '',
            userPassword: '',
            terms: false,
            privacy: false,
            loading: false,
            modal: {
                state: false,
                description: '',
                type : ''
            },
        }),
        validations: () => ({
            userName: { required },
            userEmail: { required, email },
            userPhoneNumber: { required, minLengthValue: minLength(10), maxLengthValue: maxLength(10)},
            userPassword: { required },
        }),
        computed: {
            user () {
                return {
                    "nombre"                  : this.userName,
                    "correo_electronico"      : this.userEmail,
                    "telefono"                : this.userPhoneNumber,
                    "contrasena"              : this.userPassword,
                }
            },
            userLogin () {
                return {
                    "correo_electronico"      : this.userEmail,
                    "contrasena"              : this.userPassword,
                }
            },
        },
        methods: {
            goToTerms(path){
                this.$router.push({ path: path })   
            },
            async submitForm(){
                const result = await this.v$.$validate()
                if (!result) return
                if (!this.terms) {
                    this.modal = {
                        state: true,
                        description: 'Favor de aceptar los terminos y condiciones',
                        type: 'error',
                    }

                    return
                }

                if (!this.privacy) {
                    this.modal = {
                        state: true,
                        description: 'Favor de leer el aviso de privacidad',
                        type: 'error',
                    }

                    return
                }

                this.loading = true;
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `registro?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`, this.user);

                    if (res.data.error === 1) {
                        this.loading = false;
                        this.modal = {
                            state: true,
                            description: 'Ha ocurrido un error <br><br> <span class="tx-16 tx-regular">Este usuario ya ha sido registrado.</span>',
                            type: 'error',
                        }

                        return
                    }

                    this.loading = false;
                    this.modal = {
                        state: true,
                        description: '¡Muchas gracias! <br><br> <span class="tx-16 tx-regular">Registro exitoso, estamos iniciando su sesión.</span>',
                        type: 'success',
                    }

                    console.log(res.data);

                    axios.post(process.env.VUE_APP_SERVICE_URL  + 'iniciar_sesion', this.userLogin).then(res => {
                        this.$store.commit('setLog')
                        localStorage.setItem('user-paypal-id', res.data.data.claro_pay_id)
                        this.$store.state.userId = res.data.data.claro_pay_id

                        if (res.data.error === 1) {
                            this.loading = false;
                            this.modal = {
                                state: true,
                                description: 'Ha ocurrido un error <br><br> <span class="tx-16 tx-regular">usuario o constraseña incorrecta</span>',
                                type: 'error',
                            }

                            return
                        }

                        this.$store.dispatch('checkProfile')

                        setTimeout(() => {
                            if (!this.$store.getters['isMember']) {
                                this.$router.push({ path: '/comprar-membresia' });
                                return
                            }

                            this.$router.push({ path: localStorage.getItem('history-path') }); 
                            /* this.$router.replace({ path: '/zona-premier' })  */
                        }, 1500);

                    }).catch(error => {
                        this.loading = false;
                        this.modal = {
                            state: true,
                            description: 'Ha ocurrido un error <br><br> <span class="tx-16 tx-regular">favor de intentarlo de nuevo</span>',
                            type: 'error',
                        }
                        console.log(error); 
                    })

                } catch (err) { 
                    this.loading = false;
                    this.modal = {
                        state: true,
                        description: 'Ha ocurrido un error <br><br> <span class="tx-16 tx-regular">favor de intentarlo de nuevo</span>',
                        type: 'error',
                    }
                    console.log(err); 
                }
            },
            handleAccept(state){
                this.modal = false
                
                if (state === 'error'){
                    this.modal = false
                    return
                }
                this.$router.replace({ path: '/zona-premier' }) 
            },
            nameKeydown(e) {
                if (/^\W$/.test(e.key)) e.preventDefault();
            },
            isNumber: function(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            }
        },
    }
</script>

<template>
    <div>
        <!-- NAME -->
        <div class="form-group mb-4">
            <label class="form-label form-label--cg tx-10" for="">Ingresa tu nombre</label>
            <input class="form-input form-input--cg" type="text" placeholder="Nombre" maxlength="50" v-model.trim="userName" pattern="[a-z]{1,50}">
        </div>
        <div style="color: red;" class="tx-error tx-14 opacity-1 tx-center mb-4 -mt-3" v-if="v$.userName.$error">
            Favor de llenar este campo
        </div>
        <!-- LAST NAME -->
<!--         <div class="form-group mb-4">
            <label class="form-label form-label--cg tx-10" for="">Apellidos</label>
            <input class="form-input form-input--cg" type="text" placeholder="Apellidos" maxlength="50" v-model.trim="userLastName" pattern="[a-z]{1,50}">
        </div>
        <div style="color: red;" class="tx-error tx-14 opacity-1 tx-center mb-4 -mt-3" v-if="v$.userLastName.$error">
            Favor de llenar este campo
        </div> -->
        <!-- PHONE NUMBER -->
        <div class="form-group mb-4">
            <label class="form-label form-label--cg tx-10" for="">Ingresa tu número de celular</label>
            <input class="form-input form-input--cg" type="text" placeholder="Número telefónico" maxlength="10" v-model.trim="userPhoneNumber" pattern="[a-z]{1,50}" @keypress="isNumber($event)">
        </div>
        <div style="color: red;" class="tx-error tx-14 opacity-1 tx-center mb-4 -mt-3" v-if="v$.userPhoneNumber.$error">
            Favor de llenar este campo
        </div>
        <!-- EMAIL -->
        <div class="form-group mb-4">
            <label class="form-label form-label--cg tx-10" for="">Correo electrónico</label>
            <input class="form-input form-input--cg" type="text" placeholder="Email" maxlength="50" v-model.trim="userEmail" pattern="[a-z]{1,50}">
        </div>
        <div style="color: red;" class="tx-error tx-14 opacity-1 tx-center mb-4 -mt-3" v-if="v$.userEmail.$error">
            Favor de escribir un correo electrónico válido
        </div>
        <!-- PASSWORD -->
        <div class="form-group mb-4">
            <label class="form-label form-label--cg tx-10" for="">Contraseña</label>
            <input class="form-input form-input--cg" type="password" placeholder="Contraseña" maxlength="50" v-model.trim="userPassword" pattern="[a-z]{1,50}">
        </div>
        <div style="color: red;" class="tx-error tx-14 opacity-1 tx-center mb-4 -mt-3" v-if="v$.userPassword.$error">
            Favor de llenar este campo
        </div>

        <!-- TERMS -->
        <div class="flex align-center w-fit mb-4 mx-auto">
            <label class="form-switch mr-3">
                <input class="form-switch-input" type="checkbox" id="terms_conditions" v-model="terms">
                <span class="form-switch-slider"></span>
            </label>
            <span class="tx-white tx-14 tx-underline tx-medium cursor-pointer" @click="goToTerms(`/terminos-init`)" link>
                Acepta Términos y Condiciones
            </span>
        </div>

        <!-- PRIVACY -->
        <div class="flex align-center w-fit mb-4 mx-auto">
            <label class="form-switch mr-3">
                <input class="form-switch-input" type="checkbox" id="avisos_de_privacidad" v-model="privacy">
                <span class="form-switch-slider"></span>
            </label>
            <span class="tx-white tx-14 tx-underline tx-medium cursor-pointer" @click="goToTerms(`/aviso-init`)" link>
                He leído el Aviso de Privacidad
            </span>
        </div>
        <p class="tx-center tx-purple-3 tx-bold">
            Todos los datos son obligatorios
        </p>

        <my-button class="btn--white max-w-38 mx-auto" label="Registrarme" path="/zona-premier" @clicked="submitForm()"/>

        <!-- LOADER -->
        <div class="overlay fixed grid place-items-center t-0 l-0 w-100pr h-100pr z-10" v-if="loading">
            <div class="loader-spinner"></div>
        </div>

        <!-- MODAL -->
        <transition name="fade">
            <modal-alert v-if="modal.state" @close="handleAccept(modal.type)">
                <div class="p-4">
                    <p class="tx-22 tx-bold tx-center mb-9" v-html="modal.description"></p>
                    <button class="btn mx-auto flex" :class="modal.type === 'success' ? 'btn--success' : 'btn--danger'" @click="handleAccept(modal.type)" v-if="modal.type != 'success'">
                        Aceptar
                    </button>
                </div>
            </modal-alert>
        </transition>
    </div>
</template>
<style lang="scss" scoped>    
    .tx-error{
        color: rgb(255, 69, 69)!important;
    }

    .form-input--cg,
    .form-label--cg{
        font-size: 14px!important;
    }

    .form-label--cg{
        padding: 5px 12px;
    }

    .form-input--cg{
        height: 30px;

        &::placeholder{
            font-size: 14px;
        }
    }
</style>