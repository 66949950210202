<script>
    import MyButton from './ui/MyButton.vue';
    
    export default {
        components: { MyButton },
        props: {
            data: Object
        },
        data: () => ({
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }),
        methods :{
            handleClick(path){
                this.$router.push({ path: path });
            },
            goToProduct(id){
                this.trackLog({ 
                    action: 'Click en producto comprado', 
                    medio: 'Botón', 
                    sectionId: 7, 
                    elementId: null, 
                })

                this.$router.push({ path: `/producto/${id}` });
            }
        },
        computed: {
            date() {
                const date = new Date(this.data.fecha_compra)
                return date.getDate() + ' de ' + this.months[date.getMonth()] 
            }
        }
    }
</script>
<template>
    <div class="shopping-history-item relative py-3 overflow-hidden z-0 rounded-14">
        <div class="flex flex-column sm:flex-row align-center justify-between tx-14 tx-purple px-4 md:px-8 cursor-pointer" @click="goToProduct(data.producto_id)">
            <img class="hidden sm:flex" src="@/assets/images/views/store/icon-shoped.png" alt="">
            <p class="tx-strong mb-1 sm:m-0 px-3" v-html="data.titulo"></p>
            <p class="tx-purple-2 tx-medium mb-1 sm:mb-0 px-3" v-html="date"></p>
            <p class="tx-strong mb-1 sm:m-0 px-3">PAGADO CON PAYPAL</p>
            <div class="tx-bold bg-white px-2 py rounded-8 w-fit mx-3">${{ data.monto }}</div>
        </div>
    </div>
</template> 
<style lang="scss" scoped>
    .shopping-history-item{
        background-color: rgba($color: #fff, $alpha: 0.6);
    }

</style>