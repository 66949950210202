<script>
    import axios from 'axios'

    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import NavBarMenu from '@/components/layout/NavBarMenu.vue'
    import * as sdkclaro from "@claro/sdkclaro";
    
    export default {
        components: { HeaderComponent, NavBarMenu },
        data: () => ({
            subcategories: [],
            inputToSearch: '',
        }),
        methods: {
            async getSubcategories (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `subcategorias?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&id=` + this.$route.params.id);
                    this.subcategories = res.data;
                } catch (err) { console.log(err) }
            },
            toSearch() {
                if (this.inputToSearch == '') return [];
                this.getSubcategories().then( 
                    this.subcategories = this.subcategories.filter(i => i.nombre.toLowerCase().includes(this.inputToSearch.toLowerCase()))
                )
            },
            cleanSearch(){
                this.inputToSearch = ''
            },
            goToShopHistory(){
                this.trackLog({ 
                    action: 'Historial de compras', 
                    medio: 'Botón', 
                    sectionId: 3, 
                    elementId: null, 
                })

                this.$router.push({ path: '/historial-de-compras' });
            }
        },
        computed: {
            listResults() {
                return this.subcategories.filter(i => i.nombre.toLowerCase().includes(this.inputToSearch.toLowerCase()));
            }
        },
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true)
            this.getSubcategories();

            this.trackLog({ 
                action: 'Ingreso a subcategorías', 
                medio: 'Botón', 
                sectionId: 3, 
                elementId: this.$route.params.id, 
            })
        }
    }
</script>

<template>
    <div class="store-category-view">
        <div class="container">
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>SELECCIONA UNA CATEGORIA</span>
            </div>

            <div class="flex justify-end mb-7">
                <!-- <button class="btn btn--purple flex ml-auto" @click="cleanSearch()">
                    <img class="mr-2" src="@/assets/images/views/store/see-all.png" alt="">
                    Ver todos
                </button> -->
                <img class="flex cursor-pointer ml-4" src="@/assets/images/shop-history-btn.png" alt="" @click="goToShopHistory()">
            </div>

<!--             <div class="form-group mb-7">
                <div class="flex">
                    <img class="-mr-4 z-1 -mt-1" src="@/assets/images/layout/icon-search.png" alt="">
                    <input class="form-input rounded-r-10 pl-10" type="text" placeholder="Ingresa el nombre a buscar" maxlength="50" v-model="inputToSearch">
                </div>
            </div>

            <button class="btn btn--purple btn--search flex mx-auto tx-bold mt-3 mb-7" @click="toSearch()">BUSCAR</button> -->

            <div class="grid sm:grid-cols-2 gap-6 mb-10">
                <router-link class="shadow-1" v-for="(item, idx) in subcategories" :key="idx" :to="`/categoria/${item.categoria_id}/${item.nombre}/${item.id}`">
                    <!-- COVER -->
                    <div class="w-100pr h-35 rounded-t-20 bg-center bg-cover bg-no-repeat" :style="{ 'background-image': 'url(' + item.imagen + ')' }">
                    </div>
                    <div class="grid align-center justify-center bg-center bg-cover bg-no-repeat h-8 w-100pr tx-bold" :style="{'background-image': `url(${require('@/assets/images/views/store/label.png')})`}">
                        {{ item.nombre }}
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .form-input{
        background-color: rgba($color: #fff, $alpha: 0.5);
        border: 0;

        &::placeholder{
            color: #fff;
            font-size: 16px;
            font-weight: 500;
        }
    }
</style>