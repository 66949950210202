import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navBarMenu: false,
    userId: '',
    APPiD: '',
    profileCrated: false,
    restrictModal: {
      type: 1, // membership: 1 - profile: 2
      state: false,
      text: '¡Forma parte de Zuggy Club Gamers ahora y empieza a gozar de todos los beneficios de tu membresía! <br> <div class="tx-bold tx-glow my-1">Contrata ahora</div> Y un mes va por nuestra cuenta.',
      btnLabel: 'Comprar',
    },
    alertModalShopping: false,
    membership: false,
    profile: false,
    pagoMembresia: false,
    resultadoTransaccion: '',
    tipoDeCompra: '',
    productId: '',
    puntosUsuario: 0,
    renovacionMembresia: 0,
    usoDePuntos:  false,
    payMethod: 1,
    modalAuthActive: false,
    log: false,
    plataforma_id: 0,
    paypalSubId: '',    
    paypalSubState: false,
    userLogData: {}
  },
  getters: {
    navBarMenuOpen(state){
      return state.navBarMenu;
    },
    userId(state){
      return state.userId;
    },
    restrictModal(state){
      return state.restrictModal;
    },
    restrictModalState(state){
      return state.restrictModal.state;
    },
    paymentModalState(state){
      return state.alertModalShopping;
    },
    isMember(state){
      return state.membership;
    },
    profileCompleted(state){
      return state.profile;
    },
    renewalMembership(state){
      return state.renovacionMembresia;
    },
    modalAuth(state){
      return state.modalAuthActive;
    },
    logeddIn(state){
      return state.log
    },
    getPaypalSubId(state){
      return state.paypalSubId
    },
    getPaypalSubState(state){
      return state.paypalSubState
    },
    getUserLogData(state){
      return state.userLogData
    }
  },
  mutations: {
    setClaroPayId(state){state.userId = localStorage.getItem('user-paypal-id')},
    toggleNavBarMenu(state) { state.navBarMenu = !state.navBarMenu; },
    toggleRestrictModal(state) { state.restrictModal.state = !state.restrictModal.state; },
    setMembership(state, payload) { state.membership = payload },
    setProfileCompleted(state, payload) { state.profile = payload },
    modalAlertShopping(state) { state.alertModalShopping = !state.alertModalShopping },
    handleModalAuth(state) { state.modalAuthActive = !state.modalAuthActive },
    setLog(state){ state.log = true },
    setPayPalSubId(state, payload){ state.paypalSubId = payload },
    setPaypalSubState(state, payload){ state.paypalSubState = payload },
    setUserLogData(state, payload){ state.userLogData = payload }
  },
  actions: {
    getPlatformId({ state }){
      let qa = 'qa.zuggyclubgamers.com.mx'
      let prod = 'zuggyclubgamers.com.mx'
      let local = 'localhost'
      let host = window.location.hostname

      if (host === local) {
        state.plataforma_id = 0
        return
      }

      state.plataforma_id = host === prod ? 2 : 1      
    },
    checkMembership({ state, commit }){
      return axios.post(process.env.VUE_APP_SERVICE_URL  + `vigencia-membresia-clubgamer?usuario_id=${state.userId}`)
      .then(res => {
        return res
        if (res.data.error === 1) {
          if (res.data.suscripcion.length != 0) {
            const userId = res.data.data.usuario_id
            const subId = res.data.suscripcion[0].payer_id
  
            const Base64 = {_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}
  
            /* CREDENTIALS FROM PAYPAL */
            const clientID = 'AVoyk4hbRii1YgwcKo91Lkf6QlvKnggT9bOLS_9JQGjh9gO5rmZL2FzLhAsZlIDkFUcoXASqVq2U_5zy'
            const secret = 'EOOvci-5Z9jCAzl7kjnyb2a_3N3BJhVnlmNo4s1W4FRH3B9TzgKqTUd83w_4RdpFdla6OhDgoztnQHiW'
  
            const toB64 = Base64.encode(clientID+':'+secret);
  
            // ID TEST: I-TBC00VJEKE32 
            fetch(`https://api-m.paypal.com/v1/billing/subscriptions/${subId}`, {
                headers: {
                    'Authorization': `Basic ${toB64}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then((response) => response.json())
            .then((responseData) => {  
              if(responseData.status === 'CANCELLED'){
                try{
                    axios.post(process.env.VUE_APP_SERVICE_URL  + `baja-membresia?plataforma_id=${state.plataforma_id}&usuario_id=` + userId)
                    .then(res => window.location.reload());
                } catch (err) { console.log(err) }
              }
            });
          }
        }
        return res
      })
      .then(res => {
        console.log(JSON.stringify(res));
        console.log('************** ' + JSON.stringify(res.data.msg).toUpperCase() + ' **************');
        state.renovacionMembresia = res.data.expirada;
        commit('setMembership', res.data.error === 0);
      }).catch(error => { console.log(error) })
    },
    checkProfile({ state, commit }){
      return axios.post(process.env.VUE_APP_SERVICE_URL  + `perfilamiento?usuario_id=${state.userId}`).then(res => {
        commit('setProfileCompleted', res.data[0] != undefined);          
      }).catch(error => { console.log(error) })
    },
    updatePoints({ state, commit }){
      if(state.usoDePuntos){
        return axios.post(process.env.VUE_APP_SERVICE_URL  + `puntos?usuario_id=${state.userId}&puntos=${state.puntosUsuario}`).then(res => {  
          console.log("updatePoints");
          console.log(res.data);
          }).catch(error => { console.log(error) })
      }
    },
    recoverPayment({ state, commit }){
      if(state.tipoDeCompra == 'membresia'){
        console.log("In recoverPayment")
        console.log(state.resultadoTransaccion);
        return axios.post(process.env.VUE_APP_SERVICE_URL  + `pago-membresia?usuario_id=${state.userId}&producto_id=${localStorage.getItem('claro-tipo-id-producto')}&renovacion=${state.renovacionMembresia}&metodo_pago_id=1&datos_transaccion=${JSON.stringify(state.resultadoTransaccion)}`).then(res => {  
        console.log("Save membership " + res.data);
        }).catch(error => { console.log(error) })

      } else {
        return axios.post(process.env.VUE_APP_SERVICE_URL  + `pago-producto?usuario_id=${state.userId}&producto_id=${localStorage.getItem('claro-tipo-id-producto')}&metodo_pago_id=${state.payMethod}&datos_transaccion=${JSON.stringify(state.resultadoTransaccion)}`).then(res => {
          console.log("Guardo info del producto");
          console.log(res.data);
          }).catch(error => { console.log(error) })
      }
    }
  },
  modules: {},
  plugins: [
    new VuexPersistence({
      storage: window.localStorage
    }).plugin
  ]
});
