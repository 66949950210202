<script>
    import axios from 'axios';

    export default {
        data: () => ({
            current: {},
            fielType: '',
        }),     
        methods: {
            async getNew (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `detalle-evento-especial?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&id=` + this.$route.params.id);
                    this.current = res.data
                    this.fielType = this.current.imagen.split('.').pop()
                } catch (err) { console.log(err) }
            },
            buscando(){
                var info = document.getElementById("info-c");
                var span_inco = info.getElementsByTagName("p");
                var span = info.getElementsByTagName("span");
 
                for (let item of span_inco) {
                    item.style.color = "";
                }
                for (let item of span) {
                    item.style.color = "";
                }
            }
        },
        mounted() {
            this.getNew();

            this.trackLog({ 
                action: 'Ingreso a detalle de evento especial', 
                medio: 'Banner', 
                sectionId: 2, 
                elementId: this.$route.params.id, 
            })

            setTimeout(function () { this.buscando(); }.bind(this), 100)
        }
    }
</script>

<template>
    <div class="new-detail-view">
        <div class="container">

            <div class="relative mb-5" v-if="fielType === 'mp4'">
                <video class="rounded-10 border-1" controls controlsList="nodownload">
                    <source src="https://joy1.videvo.net/videvo_files/video/free/2013-08/large_watermarked/hd0992_preview.mp4" type="video/mp4">
                    Your browser does not support HTML video.
                </video>
            </div>

            <div class="relative bg-center bg-cover bg-no-repeat h-55 sm:h-65 w-100pr rounded-10 mb-5" :style="{ 'background-image': 'url(' + current.imagen + ')' }" v-else>
            </div>
            
            <!-- BODY -->
            <div class="box p-0">
                <div class="box-title py-2 px-4 tx-center tx-20 tx-bold rounded-t-10" v-show="current.titulo != null" v-html="current.titulo"></div>
                <div class="box-body forum-body tx-14 mb-6 p-9 rounded-b-10" v-html="current.descripcion"></div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .decore-shine{ max-width: initial;}

    .father-info{
        color: #fff !important;
    }

    .father-info p{
        color: #fff !important;
    }

    .father-info p span{
        color: #fff !important;
    }

    .box-title{
        background: linear-gradient(to right, #8708C6, #3C0778);
    }

    .box-body{
        background-color: rgba($color: #fff, $alpha: 0.3);
    }
</style>
