<script>
    import axios from 'axios';
    import Share from '@/components/ui/Share.vue';

    export default {
        components: { Share },
        data: () => ({
            likes: 0,
            current: {},
            response: {},
            fielType: '',
            dataNews: []
        }),     
        methods: {
            async getNew (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `detalle-noticia?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&id_noticia=` + this.$route.params.id);
                    this.current = res.data
                    this.$refs.videoRef.src = this.current.video;
                    this.fielType = this.current.imagen.split('.').pop()
                    this.likes = this.current.me_gusta
                } catch (err) { console.log(err) }
            },
            async like(id){
                
                try{
                    const tipo = "noticias"
                    const resa = await axios.post(process.env.VUE_APP_SERVICE_URL  + `me-gusta?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&id=` + this.$route.params.id +'&tipo=' + tipo +'&usuario_id=' + this.$store.getters['userId']);
                    this.likes = resa.data.numero
                    this.like_por_usuario = !this.like_por_usuario
                } catch (err) { console.log(err) }
            },
            async likesTotal(){
                
                try{
                    const resa = await axios.post(process.env.VUE_APP_SERVICE_URL  + `me-gusta-total?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&id=` + this.$route.params.id);
                    this.likes = resa.data.numero
                } catch (err) { console.log(err) }
            },
            async likeUser(){
              
                try{
                    const tipo = "noticias"
                    const resa = await axios.post(process.env.VUE_APP_SERVICE_URL  + `me-gusta-por-usuario?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&id=` + this.$route.params.id +'&tipo=' + tipo +'&usuario_id=' + this.$store.getters['userId']);
                    this.like_por_usuario = resa.data.usuario        
                } catch (err) { console.log(err) }
            },
            async getNews (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `noticias?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.dataNews = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            goToNews(path){
                this.$router.push({ path: path })
                window.location.reload().then(() => window.scrollTo({ top: 0 }))
            },
            buscando(){
                var info = document.getElementById("info-c");
                var span_inco = info.getElementsByTagName("p");
                var span = info.getElementsByTagName("span");
 
                for (let item of span_inco) {
                    item.style.color = "";
                }
                for (let item of span) {
                    item.style.color = "";
                }
            }
        },
        mounted() {
            this.getNew();
            this.getNews();
            this.likesTotal();
            this.likeUser();
            this.trackLog({ 
                action: 'Ingreso a detalle de noticia', 
                medio: 'Banner', 
                sectionId: 2, 
                elementId: this.$route.params.id, 
            })

            //setTimeout(function () { this.buscando(); }.bind(this), 100)
        }
    }
</script>

<template>
    <div class="new-detail-view pb-15">
        <div class="container">

            <!--<div class="relative mb-5" v-if="fielType === 'mp4'">-->
            <div class="relative mb-5">
                <video ref="videoRef" :poster="current.poster" class="rounded-10 w-100pr" controls controlsList="nodownload" v-if="current.video">
                    <source :src="current.video" type="video/mp4">
                    Your browser does not support HTML video.
                </video>
            </div>

<!--             <div class="relative bg-center bg-cover bg-no-repeat h-55 w-100pr rounded-10 border-1 border-white mb-5" :style="{ 'background-image': 'url(' + current.poster + ')' }">
                <img class="decore-shine absolute l-0 r-0 b-0 -mb-2 mx-auto" src="@/assets/images/layout/shine-banner.png" alt="">
            </div> -->

            <!-- BODY -->
            <div class="box p-0">
                <div class="box-title py-2 px-4 tx-center tx-20 tx-bold rounded-t-10" v-html="current.titulo"></div>
                <div class="box-body forum-body tx-14 mb-6 p-9 rounded-b-10" v-html="current.descripcion"></div>
            </div>

            <div class="flex justify-between align-center mb-10">
                <!-- LIKE -->
                <button class="btn like-button" @click="like(current.id)" >
                    <img v-if="this.like_por_usuario == true"  width="30" class="" src="@/assets/images/me_gusta_activo.png" alt=""> 
                    <img v-else width="30" class="" src="@/assets/images/me_gusta.png" alt=""> 
                    <span class="ml-2">
                        {{this.likes}}
                    </span>
                </button>

                <!-- SHARE -->
                <share/>
            </div>

            <!-- NEWS -->
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>NOTICIAS</span>
            </div>
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-5">
                <div class="shadow-3" v-for="(item, idx) in dataNews.slice()" :key="idx" @click="goToNews(`/noticia/${item.id}/titulo`)">
                    <div class="item-layer z-0 h-36 flex align-end bg-t bg-cover bg-no-repeat rounded-10" :style="{ 'background-image': 'url(' + item.imagen + ')' }">
                    </div>
                    <div class="container-noticias tx-center grid align-center justify-center h-18 rounded-b-10 p-2">
                        <h3 class="tx-14 mb-1 tx-medium overflow-hidden" v-html="item.titulo"></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .container-noticias{
        max-height: 8rem;
        position: relative;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        background-color: #3C0778;
    }

    .like-button{
        background: transparent;
        border: 0;
        color: #fff;
        padding: 0
    }
    .decore-shine{ max-width: initial;}

    .box-title{
        background: linear-gradient(to right, #8708C6, #3C0778);
    }

    .box-body{
        background-color: rgba($color: #fff, $alpha: 0.3);
    }
</style>