<script>
    import axios from 'axios';
    import ModalAlert from '@/components/ModalAlert.vue';

    export default {
        components: { ModalAlert },
        props: {
            data: Object
        },
        data: () => ({
            modal: false,
            restModal: true
        }),
        methods: {
            handleClick(path){
                this.trackLog({ 
                    action: 'Click en producto', 
                    medio: 'Botón', 
                    sectionId: 3, 
                    elementId: null, 
                })

                this.$router.push({ path: path });
            },
            async schedule(id){
                this.trackLog({ 
                    action: 'Me interesa el torneo con el id: ' + id, 
                    medio: 'Botón', 
                    sectionId: 1, 
                    elementId: this.$route.params.id, 
                })
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `registro-torneo?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=`+ this.$store.getters['userId'] +'&torneo_id=' + id);
                    console.log(res.data)
                   
                    if(res.data.error == 1){
                        this.restModal = false
                    } else {
                        this.restModal = true
                    }
                    this.$emit('close')
                    this.modal = true
                    this.$emit('modalResultInfo', this.modal, this.restModal)
                    
                } catch (err) { console.log(err) }
                console.log("No entra")
            },
            handleTournament(url){
                //window.open(time === 1 ? 'https://battlefy.com/zuggy-club-gamers' : 'https://torneos.zuggyclubgamers.com/', '_blank')
                /* window.open('https://battlefy.com/zuggyclubgamers/clash-royale-zuggy-club-gamers/67b7c1a6408c1402cfdd34f7/info?infoTab=details', '_blank') */
                window.open(url, '_blank')
            }
        }
    }
</script>
<template>
     <div class="product-item block relative cursor-pointer py-5 sm:py-0">
        <!-- <div class="flex align-center sm:align-end relative z-1"  @click="handleTournament(data.temporalidad)"> -->
            <div class="flex align-center sm:align-end relative z-1"  @click="handleTournament(data.url)">

            <div class="bg-center bg-cover bg-no-repeat h-30 sm:h-60 w-30 sm:w-60 xl:w-100 flex-none rounded-16 mr-5" :style="{ 'background-image': 'url(' + data.imagen + ')' }"></div>

            <div class="tx-12 sm:tx-16 relative w-100pr pb-4 xl:pb-8 pr-10 md:pr-15">
                
                <img class="badge hidden md:block absolute r-0 t-0 mr-6 lg:mr-13 -mt-2" src="@/assets/images/views/test-badge-tournament.png" alt="">

                <p class="tx-strong mb-2">
                    {{ data.titulo }}
                </p>
                <p class="tx-medium mb-0">
                    FECHA: <span class="tx-purple-3">{{ data.fecha_torneo }}</span>
                </p>
                <p class="tx-medium mb-0 sm:mb-4">
                    PREMIO: <span class="tx-purple-3">${{ data.recompensa }}</span>
                </p>
                <div class="tx-12 tx-medium flex align-center w-100pr tx-10">
                    PATROCINADO POR: <div class="h-10 w-10 bg-cover bg-center bg-no-repeat rounded-100pr ml-3" :style="{ 'background-image': 'url(' + data.patrocinador + ')' }"></div> 
                </div>
                <div class="tx-14 md:tx-18 tx-center md:ml-auto md:h-7 w-15 md:w-20 tx-bold bg-white tx-purple rounded-16 px-2 sm:pt-1 mb-3">
                    ${{ data.costo }}
                </div>
                <div class="tx-8 sm:tx-10">
                    Para recibir la información es necesario que registres tu correo electrónico en tu perfil.
                </div>
            </div>
        </div>

        <img class="decore absolute t-0 r-0 b-0 m-auto h-100pr" src="@/assets/images/views/store/bg-product.png" alt="">

        <layer-restrict v-if="!$store.getters['isMember']"/>
    </div>
</template>
<style lang="scss" scoped>
    .item-a{
        display: flex;
        vertical-align: middle;
        align-items: center;
    }

    .item-a div {
        margin-left: 10px;
    }

    .btn{
        color: #fff;
        background-color: rgba($color: #fff, $alpha: 0.35);
        border: none;
    }
</style>

