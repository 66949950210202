<script>
    import axios from 'axios';

    import HeaderComponent from '@/components/layout/HeaderComponent.vue';
    import NavBarMenu from '@/components/layout/NavBarMenu.vue';

    export default {
        components: { HeaderComponent, NavBarMenu },
        data: () => ({
            current: {},
        }),     
        methods: {
            async getReward (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `detalle-beneficios-zuggy?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&id_beneficio=` + this.$route.params.id);
                    this.current = res.data
                } catch (err) { console.log(err) }
            },
        },
        mounted() {
            this.getReward();

            this.trackLog({ 
                action: 'Ingreso a detalle de recompensa', 
                medio: 'Banner', 
                sectionId: 9, 
                elementId: this.$route.params.id, 
            })
        }
    }
</script>

<template>
    <div class="reward-detail-view">
        <div class="container">
            <img class="rounded-10 mb-5 mx-auto" :src="current.imagen" alt="">
            <!-- <div class="relative bg-center bg-cover bg-no-repeat h-55 sm:h-76 w-100pr rounded-10 mb-5" :style="{ 'background-image': 'url(' + current.imagen + ')' }">
            </div> -->

            <!-- BODY -->
            <div class="box p-0">
                <div class="box-title py-2 px-4 tx-center tx-20 tx-bold rounded-t-10" v-html="current.titulo" v-show="current.titulo != null"></div>
                <div class="box-body forum-body tx-14 mb-6 p-9 rounded-b-10" v-html="current.descripcion"></div>
            </div>
        </div>

        <!-- NAV BAR MENU -->
        <transition name="nav-bar">
            <nav-bar-menu v-show="$store.getters['navBarMenuOpen']"/> 
        </transition>
    </div>
</template>
<style lang="scss" scoped>
    .box-title{
        background: linear-gradient(to right, #8708C6, #3C0778);
    }

    .box-body{
        background-color: rgba($color: #fff, $alpha: 0.3);
    }
</style>