<script>
    export default {
        data: () => ({
            videoPlaying: false 
        }),
        methods: {
            togglePlayVideo(){
                const video = this.$refs.myVideo;
                const btnControl = this.$refs.btnControl;

                btnControl.style.opacity = '1';
                !video.paused ? video.pause() : video.play();
                this.videoPlaying = !video.paused;
                !video.paused && setTimeout(() => btnControl.style.opacity = '0', 1000);
            },
            close() {
                this.$refs.myVideo.pause();
                this.$refs.myVideo.currentTime = 0;
                this.$emit('close');
            }
        },
        computed: {
            btnControl(){
                return !this.videoPlaying ? 'icon-play' : 'icon-pause';
            }
        },  
    }
</script>
<template>
    <div class="modal flex align-center justify-center overflow-hidden py-0">
        <div style="font-size: 20px; right: 25px;" class="modal-close tx-white" @click="close();">
           <!-- &times;-->
            omitir
        </div>
        <div class="wrapper-video flex justify-center align-center h-100pr w-100pr relative">
            <video poster="@/assets/videos/poster.png" ref="myVideo" class="max-w-fit h-100pr">
                <source src="@/assets/videos/welcome.mp4" type="video/mp4">
                Your browser does not support HTML video.
            </video>

            <!-- LAYER CONTROL -->
            <div class="absolute grid place-items-center h-100pr w-100pr" @click="togglePlayVideo()">
                <div ref="btnControl" class="h-17 w-17 -mr-8 transition-200">
                    <img class="h-100pr w-100pr" :src="require(`@/assets/images/layout/${ btnControl }.png`)" alt="">
                </div>
            </div>
        </div>
    </div>
</template>