<script>
    import axios from 'axios'
    import { useVuelidate } from '@vuelidate/core'
    import { required } from '@vuelidate/validators'

    import UploadAvatar from '@/components/layout/UploadAvatar.vue'
    import MyButton from '@/components/ui/MyButton.vue'
    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import * as sdkclaro from "@claro/sdkclaro";
    
    export default {
        components: { UploadAvatar, MyButton, HeaderComponent },
        setup () {
            return { v$: useVuelidate() }
        },
        data: () => ({
            edad: '',
            userProfile: {},
            membresiaProfile: {},
        }),
        validations: () => ({
            userName: { 
                required,
            },
        }),
        methods: {
            async getProfile() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `perfilamiento?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}`)
                    this.userProfile = res.data[0];

                    var hoy = new Date();
                    var cumpleanos = new Date(res.data[0].fecha_de_nacimiento);
                    this.edad = hoy.getFullYear() - cumpleanos.getFullYear();

                } catch (err) { console.log(err) }
            },
            async getMembresiaProfile() {
                try {
                    const a = await axios.post(process.env.VUE_APP_SERVICE_URL  + `vigencia-membresia-clubgamer?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}`)
                    this.membresiaProfile = a.data.data;
                    console.log(a);
                    
                } catch (err) { console.log(err) }
            },
            handleButton(path, action){
                this.trackLog({ 
                    action: action, 
                    medio: 'Botón', 
                    sectionId: 7, 
                    elementId: null, 
                })

                this.$router.push({ path: path });
            }
        },
        computed: {
            avatar() {
                return this.userProfile.avatar === null ? require('@/assets/images/views/create_profile/avatar-default.png') : this.userProfile.avatar
            }
        },  
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true)
            this.getProfile()
            this.getMembresiaProfile()
            this.trackLog({ 
                action: 'Ingreso a perfil', 
                medio: 'Botón', 
                sectionId: 7, 
                elementId: null, 
            })
        },
    }
</script>
<template>
    <div class="create-profile-view pb-15">
        <div class="container">
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>MI PERFIL</span>
            </div>
            <div class="box-info box flex align-center justify-center p-4 mx-auto">
                <!-- <img class="mr-5 hidden sm:block" src="@/assets/images/views/user/control.png" alt=""> -->
                <div class="flex flex-column align-center sm:align-left">
                    <p class="tx-30 tx-bold mb-2">
                        {{ userProfile.nombre }}
                    </p>
                    <div class="bg-purple tx-20 tx-bold rounded-6 w-fit py-1 px-4 mb-3">
                        {{ userProfile.puntos }} puntos
                    </div>
                </div>
            </div>
            <div class="tx-14 tx-medium mx-auto w-fit py-2 px-8 bg-purple-2 rounded-8 sm:-mt-2 mb-5">
                Vigencia de membresía: {{ membresiaProfile.fecha_fin | formatDate }}
            </div>
            <div class="box box-info box-purple p-4 sm:p-7 mx-auto tx-center mb-8">
                <p class="mb-1">
                    Plataforma de juegos preferida: <span class="tx-purple">{{ userProfile.plataforma }}</span>
                </p>
                <p class="mb-1">
                    Preferencia por videojuegos de tipo: <span class="tx-purple">{{ userProfile.juegos }}</span>
                </p>
                <p class="mb-0">
                    Streamer favorito: <span class="tx-purple">{{ userProfile.referencias }}</span>
                </p>
            </div>

            <div class="flex flex-column sm:flex-row justify-center align-center w-fit mx-auto">
                <div class="w-fit sm:mr-6 mb-4 sm:mb-0" @click="handleButton('/editar-perfil', 'Editar perfil')">
                    <img class="cursor-pointer" src="@/assets/images/views/user/btn-edit.png" alt="">
                </div>
                <div class="w-fit" @click="handleButton('/historial-de-compras', 'Historial de compras')">
                    <img class="cursor-pointer" src="@/assets/images/views/user/btn-history.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .box{
        background-color: rgba($color: #fff, $alpha: 0.35);

        &-info{
            max-width: 564px;
        }
    }
    
    .avatar{
        box-shadow: 0 4px 8px rgba($color: #000, $alpha: 0.35);
    }
</style>