<script>
    import HeaderComponent from '@/components/layout/HeaderComponent.vue';
    import FooterComponent from '@/components/layout/FooterComponent.vue';

    import * as sdkclaro from "@claro/sdkclaro";
    import ModalAuth from '@/components/auth/ModalAuth.vue';
import BtnMembership from '@/components/layout/BtnMembership.vue';

    export default {
        components: { HeaderComponent, FooterComponent, ModalAuth, BtnMembership },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        mounted() {
            sdkclaro.getInstance().switchGoBackButton(true)
            this.$store.dispatch('checkMembership')
        }
    }
</script>

<template>
    <div class="home-view flex flex-column justify-between app-view pt-20">
        <header-component/>

        <router-view class="pt-8"/>

        <footer-component/>

        <modal-auth v-if="$store.getters['modalAuth']"/>

        <btn-membership v-if="!this.$store.getters['isMember']"></btn-membership>
    </div>
</template>
<style lang="scss" scoped>
    .router-link-active{
        background-color: black;
        color: #fff;
    }
</style>