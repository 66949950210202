<script>
    import axios from "axios"
    import * as sdkclaro from "@claro/sdkclaro";
    import ModalIke from '@/components/ModalIke.vue'
    import ModalVideoPlayer from "../ModalVideoPlayer.vue";

    export default {
        components: { ModalIke, ModalVideoPlayer },
        props: { ikeMembership: Number },
        data: () => ({
            nuevaInformacion: false,
            modal: false,
            modalForm: false,
            ikeMembership: null,
            menuOptions: [
                { title: 'Zona Premier', icon: 'ground', path: '/zona-premier' },
                { title: 'Tienda', icon: 'shop', path: '/tienda' },
                { title: 'Comunidad', icon: 'community', path: '/comunidad' },
                { title: 'Recompensas', icon: 'rewards', path: '/recompensas' },
                { title: 'Asistencia', icon: 'help', path: '/asistencia' },
                { title: 'Preguntas frecuentes', icon: 'faqs', path: '/preguntas-frecuentes' },
            ],
        }),
        methods: {
            async notificacionTerminos() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `indicador-terminos?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`)
                    

                    if(res.data.detalle.activo == 1){
                        this.nuevaInformacion = true
                    }
                } catch (err) { console.log(err); }
            },
            async ikeValidation() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `ike-vigencia?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}`)
                    this.ikeMembership = res.data.error
                    console.log("Async")
                    console.log(res.data);
                } catch (err) { console.log(err); }
            },
            handleModalForm(){

                if(!this.$store.getters['isMember']){
                    return;
                }
                
                this.ikeValidation()
                console.log("ikeValidation " + this.ikeMembership)
                
                if (this.ikeMembership === 1) { 
                    this.modalForm = !this.modalForm 
                    return
                }
                sdkclaro.getInstance().getTopic("EXECUTE_Minimal_View", {
                    typeOperation: "openUrl",
                    parameters: "tel:+525590633558",
                });  
            },
            getShowVideo (){
                //try{
                    /*const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + 'welcome-video?usuario_id=' + localStorage.getItem('user-paypal-id'));
                    this.current = res.data

                    if(this.current.video == 1){*/
                    this.modal = true;
                    this.showVideo = true
                    /*} else {
                        this.showVideo = false
                    }*/

                //} catch (err) { console.log(err) }
            },
            closeModal() {
                this.modal = false;
                this.trackLog({ 
                    action: 'Video visto', 
                    medio: 'Botón', 
                    sectionId: 1, 
                    elementId: null, 
                })
            },
            changeRoute(path) {
                this.$store.commit('toggleNavBarMenu');
                this.$router.push({ path: path });
            },
            closeMenu(){
                this.$store.commit('toggleNavBarMenu')
                this.trackLog({ 
                    action: 'Cerrar menu principal', 
                    medio: 'Botón', 
                    sectionId: null, 
                    elementId: null, 
                })
            },
            handleIke(){
                this.$emit('checkIke')
            }
        },
        mounted(){
            this.notificacionTerminos()
        }
    }
</script>
<template>
    <div class="tx-14 tx-medium tx-purple nav-bar-menu w-100pr h-100vh absolute t-0 l-0 z-10">
        <div class="nav-bar-menu-inner w-50pr flex flex-column justify-between bg-white ml-auto h-100pr py-30 transition-200">

            <!-- MENU -->
            <div class="px-6">
                <div class="h-10 w-10 grid place-items-center ml-auto -mr-3 mb-10" @click="closeMenu()">
                    <img src="@/assets/images/layout/icon-times-menu.svg" alt="">
                </div>

                <nav>
                    <ul class="bullet-none p-0 m-0 w-100pr">
                        <li class="flex align-center w-100pr mb-5" @click="changeRoute(opt.path)" v-for="(opt, idx) in menuOptions" :key="idx">
                            <div class="h-7 w-7 grid place-items-center flex-none mr-4">
                                <img :src="require(`@/assets/images/layout/icon-menu/icon-${opt.icon}.svg`)" alt="">
                            </div>
                            {{ opt.title }}
                            <div class="p-relative" v-if="idx == 4">
                                <span v-if="nuevaInformacion" class="red-button-notification"></span>
                            </div>
                        </li>
                        <li class="flex align-center w-100pr mb-5 relative" @click="handleModalForm()">
                            <router-link class="flex align-center" active-class="active" to="/soporte-tecnico" exact>
                                <div class="h-7 w-7 grid place-items-center flex-none mr-4">
                                    <img :src="require(`@/assets/images/layout/icon-menu/icon-soporte.svg`)" alt="">
                                </div>
                                Guía Técnica.
                            </router-link>
                            <layer-restrict v-if="!$store.getters['isMember']"/>
                            <div class="t-0 l-0 absolute w-100pr h-100pr" @click="handleIke()" v-if="ikeMembership != 0"></div>
                        </li>
                        <li class="flex align-center w-100pr mb-5 relative" @click="getShowVideo()">
                            <div class="h-7 w-7 grid place-items-center flex-none mr-4">
                            <!--    <img :src="require(`@/assets/images/layout/icon-menu/icon-soporte.svg`)" alt="">-->
                            </div>
                            Ver video
                        </li>
                    </ul>
                </nav>
            </div>
            <!-- PROFILE -->
            <div class="mx-5 border-t-1 border-purple pt-7" v-if="$store.getters['isMember']">
                <div class="flex align-center w-100pr mb-5" @click="changeRoute($store.getters['profileCompleted'] ? '/mi-perfil' : '/crear-perfil')">
                    <img class="mr-4 ml-1" style="width: 28px; height: 28px;" src="@/assets/images/layout/icon-menu/perfil.png" alt="">
                    Perfil
                </div>
            </div>
            <transition name="fade">
                <!-- <modal-ike v-if="modalForm" @close="handleModalForm()"></modal-ike> -->
                <modal-video-player @close="closeModal()" v-if="modal"/>
            </transition>
  
        </div>
    </div>
</template>
<style lang="scss" scoped>

    .p-relative{
        position: relative;
        width: 8px;
        height: 8px;
        margin-left: 5px;
    }

    .red-button-notification{
        background: red;
        width: 5px;
        height: 5px;
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .nav-bar-menu{
        background-color: rgba($color: #000000, $alpha: 0.3);

        &-inner{
            box-shadow: 0 -19px 20px rgba($color: #000000, $alpha: 0.16);
            width: 56%;
        }
    }
</style>