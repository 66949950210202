<script>
    import axios from "axios"
    import MyButton from '@/components/ui/MyButton.vue';

    export default {
    components: { MyButton },
        data: () => ({
            render: false,
            _swiperTop: {
                allowTouchMove: true,
                slidesPerView: 2,
                centeredSlides: true,
                spaceBetween: 20,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                breakpoints: {
/*                     640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    }, */
                    1024: {
                        slidesPerView: 3,
                    },
                },
            },
            swiperTop: {
                allowTouchMove: true,
                slidesPerView: 1.9,
                centeredSlides: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                effect: 'coverflow',
                coverflowEffect: {
                    rotate: 1,
                    stretch: 96,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }
            },
            dataPromotions: [],
            dataCategories: [],
            bottomBanner: {},
        }),
        methods: {
            async getPromotions (){
                try{
                    /* const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + 'promociones-tienda'); */
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `relevantes?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.dataPromotions = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            async getCategories (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `categorias-tienda?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.dataCategories = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            async getBottomBanner (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `banner-rasca-gana?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.bottomBanner = res.data;
                } catch (err) { console.log(err) }
            },
            handleCategory(path){
                this.trackLog({ 
                    action: 'Click en categoría', 
                    medio: 'Botón', 
                    sectionId: 3, 
                    elementId: null, 
                })

                this.$router.push({ path: path });
            },
            handleClick(path){
                this.trackLog({ 
                    action: 'Click en producto desde lo mas relevante', 
                    medio: 'Botón', 
                    sectionId: 3, 
                    elementId: null, 
                })

                this.$router.push({ path: path });
            },
            goToShopHistory(){
                this.trackLog({ 
                    action: 'Historial de compras', 
                    medio: 'Botón', 
                    sectionId: 3, 
                    elementId: null, 
                })

                this.$router.push({ path: '/historial-de-compras' });
            }
        },
        mounted() {
            this.getPromotions();
            this.getCategories();
            this.getBottomBanner();

            setTimeout(() => this.render = true, 0);

            this.trackLog({ 
                action: 'Ingreso a tienda', 
                medio: 'Botón', 
                sectionId: 3, 
                elementId: null, 
            })
        },
    }
</script>
<template>
    <div class="store-view">
        <div class="container">
            <div class="relative">
                <div class="sm:flex align-center mb-8 sm:mb-15">
                    <div class="decore-title-lg flex align-center justify-center h-20 sm:h-10 w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic">
                        <span>!Los códigos digitales más relevantes del mes¡</span>
                    </div>
                    <img class="flex cursor-pointer ml-auto mt-3 sm:mt-0" src="@/assets/images/shop-history-btn.png" alt="" @click="goToShopHistory()" v-if="$store.getters['isMember']">
                </div>

                <!-- SWIPER -->
                <swiper class="swiper mb-10 sm:mb-10" :options="swiperTop" ref="swiperTop" v-if="render">
                    <swiper-slide v-for="(item, idx) in dataPromotions" :key="idx">
                        <div class="relative z-0 flex align-end w-100pr h-61 sm:h-90 relative rounded-10 p-4" @click="handleClick(`/producto/${item.id_producto}`)">
                            <img class="h-100pr" style="max-width: initial;" :src="item.imagen" alt="">
                            <!-- <div class="max-w-75pr">
                                <h3 class="tx-14 mb-0 tx-medium">
                                    {{ item.titulo }}
                                </h3>
                                <p class="tx-12 mb-0">
                                    {{ item.descripcion }}
                                </p>
                            </div> -->
                        </div>
                    </swiper-slide>
                </swiper>
                
                <!-- LAYER RESTRICT -->
                <layer-restrict v-if="!$store.getters['isMember']"/>
            </div>

            <!-- LIST -->
            <div class="grid justify-center align-center md:grid-cols-3 mb-8 sm:gap-3"> 
                <div class="cursor-pointer relative bg-center bg-contain bg-no-repeat" :style="{'background-image' : `url(${require('@/assets/images/layout/bg-square.png')})`}" v-for="(item, idx) in dataCategories" :key="idx" @click="handleCategory(item.sub_categoria === 1 ? `/subcategorias/${item.nombre}/${item.id}` : `/categoria/${item.id}/${item.nombre}/${item.sub_categoria}`)">
                    <img class="opacity-0" src="@/assets/images/views/store/categories/platforms.png" alt="">
                    <div class="flex align-center absolute t-0 l-0 w-100pr h-100pr px-5 py-7">
                        <div class="flex flex-none align-center w-24 w-15 xl:w-24 xl:ml-4">
                            <img class="w-100pr" :src="item.icono" alt="">
                        </div>
                        <p class="tx-12 xl:tx-16 tx-bold w-100pr tx-center m-0 pr-3">
                            {{ item.nombre }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .category-item{
        background-color: rgba($color: #000000, $alpha: 0.2);
    }

    .btn-history{
        font-size: 10px!important;
    }

    /* .item-layer::after{
        background-image: linear-gradient(rgba(#000, 0), rgba(#000, 0.2), rgba(#000, 1));
    } */
</style>