<script>
    export default {
        props: {
            question: String,
            answer: String
        },
        data: () => ({
            dropdown: false
        }),
        methods: {
            toggleDropdown(){
                this.dropdown = !this.dropdown;
            },
            beforeEnter(el){
                el.style.height = 0
            },
            enter(el){
                el.style.height = el.scrollHeight + 'px'
            },
            beforeLeave(el){
                el.style.height = el.scrollHeight + 'px'
            },
            leave(el){
                el.style.height = 0
            },
        },
    }
</script>

<template>
    <div class="dropdown">
        <div class="dropdown-header relative tx-14" @click="toggleDropdown()">
            {{ question }}

            <img src="@/assets/images/layout/angle-box.svg" alt="" class="dropdown-icon ml-4" :class="[ dropdown && 'active' ]">
        </div>

        <transition
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave">
            <div class="dropdown-wrapper tx-12" v-if="dropdown">
                <div class="py-3 px-5" v-html="answer"></div>
            </div>
        </transition>
    </div>
</template>
<style lang="scss" scoped>
    .dropdown {
        /* margin-bottom: 12px; */
        background-color: rgba($color: #000000, $alpha: 0.15);
        border-radius: 6px;
    }
    .dropdown:last-child {
        margin-bottom: 0;
    }
    .dropdown-header {
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: 0 20px;
    }
    .dropdown-icon {
        max-width: 24px;
        transition: transform 200ms ease;
    }
    .dropdown-icon.active {
        transform: rotate(180deg);
    }
    .dropdown-wrapper {
        overflow: hidden;
        transition: height 400ms ease;
        background-color: rgba($color: #000000, $alpha: 0.1);
    }
</style>