<script>
    import axios from 'axios'
    import MyButton from '@/components/ui/MyButton.vue';
    import ModalAlert from '@/components/ModalAlert.vue';
    import * as sdkclaro from "@claro/sdkclaro";
    import { loadScript } from "@paypal/paypal-js";
   
    export default {
        components: {  MyButton, ModalAlert },
        head: {
            script: [
                {
                    type: "text/javascript",
                    src: "https://www.paypal.com/sdk/js?client-id=AdZmY2n_0tz0sS1qCQGfn8x7PsHl1qtin1FPdRG7qbMC_bcVRkIdRUwlJI8d3cfCG7cO30pyxAQecpjz&currency=MXN&locale=es_MX",
                },
            ],
        },
        data: () => ({
            memberships: [
                { id: 1, name: 'x meses + x mes gratis' },
                { id: 2, name: 'x meses con xx%' },
                { id: 3, name: 'x año a $xxx' },
            ],
            planAmount: 0,
            form: {
                planSelected: 0,
                terms: false,
                codigo: '',
                pagoAceptado: false,
                renovation: true
            },
            successCode: false,
            loading: false,
            modal: {
                state: false,
                description: '',
                type : ''
            },
            nombreMembresia: '',
            montoDeDescuento: 0,
            toDesc: {},
            toLabel: {},
            newAmount: 0
        }),
        methods: {
            calPercnt(num, percentage) {
                const result = num * (percentage / 100);
                return parseFloat(result.toFixed(2));
            },
            terms(path){
                console.log("Accediendo a terminos")
                this.$router.push({ path: path })   
            },
            montoSeleccionado(monto, nombre){
                this.planAmount = monto
                this.nombreMembresia = nombre
            },
            async getMemberships(){
                try {
                    //const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `membresias?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `membresias?plataforma_id=1`);
                    console.log(res.data);
                    let arr = res.data.filter(i => i.activo === 1)
                    this.toDesc = arr[3]
                    this.toLabel = arr[4]

                    this.form.planSelected = this.memberships[0].id
                    this.planAmount = this.memberships[0].monto
                    this.nombreMembresia =  this.memberships[0].nombre
                    
/*                     if (this.form.renovation) {
                        this.memberships = [arr[0]];
                        return
                    } */
                    /* this.memberships = [arr[0], arr[1], arr[2]]; */
                    this.memberships = arr
                    
                } catch (err) { console.log(err) }
            },
            async purchaseMembership(){
                this.loading = true
                this.$store.state.tipoDeCompra = 'membresia'

                if(!this.form.renovation) {
                    this.loading = false;
                    this.modal = {
                        state: true,
                        /* description: 'Debe de aceptar los términos y condiciones', */
                        description: 'Debes de aceptar la renovación de tu membresia',
                        type: 'error',
                    }
                    return
                }

                //Codigo valido = zucg2319ref
                if(this.form.codigo != ''){
                    
                    let params = {
                        codigo : this.form.codigo
                    }

                    axios.post(process.env.VUE_APP_SERVICE_URL  + `codigoReferenciados?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&`, params).then(res => {
                        console.log(res)

                        this.loading = false

                        if(res.data.error == 1){
                            this.modal = {
                                state: true,
                                description: 'Código Inválido',
                                type: 'error',
                            }
                            this.form.codigo = ''   
                        } else {
                            this.montoDeDescuento = this.calPercnt(this.planAmount, res.data.data.monto_porcentaje);
                            this.purchaseMembershipCode()
                        }
                    
                    }).catch(error => { console.log(error) })

                    return
                }
                this.purchaseMembershipCode()
            },

            async purchaseMembershipCode(){
                
                this.loading = true
                this.$store.state.tipoDeCompra = 'membresia'
                this.planAmount = this.planAmount - this.montoDeDescuento;
                
                if(this.planAmount == 0){
                    this.planAmount = 1;
                }
                
                try {

                    var operationIdComercio = "Zug-8da41970-4c96-11ec-973e"
                    var cardNumberSkuProducto = "MEMB"
                    var appId = localStorage.getItem('claro-id-open')
                    var idState;
                    let dataStateProcess = {
                        cardNumber: cardNumberSkuProducto,
                        idCom: "0074",
                        idGrp: "0002",
                        checkDigit: 1,
                        amount: this.planAmount.toString(),
                        appId: appId
                    }
                    console.log("Datos a enviar al setState")
                    console.log(dataStateProcess)

                    sdkclaro.getInstance().setState( 
                        operationIdComercio,
                        JSON.stringify(dataStateProcess),
                    (result)=>{
                        console.log(result)
                        try {
                            console.log('TRY setState')
                            const res = result;
                            idState = res.idState
                            localStorage.setItem('claro-id-state', idState)
                            console.log(res)
                        } catch (error) { console.log(error); }
                        
                    },
                    (error)=>{
                        console.log(error)
                    })
                    
                    console.log("Monto a pagar v2: " +  this.planAmount)
                    console.log("Operation-id: " + operationIdComercio)
                    let userId = localStorage.getItem('user-paypal-id')
                    let merchantId = '000000008e1f2f2b018e1f2f2b9d0000'
                    

                    let comercio = 'ZCG'
                    var fecha = new Date(); 
                    var anio = fecha.getFullYear();
                    var mes = fecha.getMonth();
                    var dia = fecha.getDay();
                    var hour = fecha.getHours();
                    var minute = fecha.getMinutes();
                    var second = fecha.getSeconds();
                    var reference = comercio + anio + mes + dia + hour + minute + second; //Nuevo concepto 

                    let data = {
                        amount: this.planAmount.toString(),
                        category: "MA",
                        userId: userId,
                        concept: "Membresía Zuggy " + this.nombreMembresia,
                        description: "Membresía Zuggy " + this.nombreMembresia,
                        feeAmount: 0,
                        merchantId: merchantId,
                        operationId: operationIdComercio,//Numero aleatorio generado por el comercio como Identificador de la operacion
                        reference: reference.toString(),//Numero de la referencia del pago
                        totalCommission: 0
                    }

                    sdkclaro.getInstance().transactionPayment(
                    data,
                    (result)=>{
                        this.loading = false;
                        setTimeout(()=>{
                            this.modal = {
                                state: false,
                                description: '¡Muchas gracias! <br><br> <span class="tx-16 tx-regular">Su pago ha sido exitoso.</span>',
                                type: 'success',
                            }
                        },2000);                
                        localStorage.setItem('claro-tipo-servicio', 'membresia')   
                        localStorage.setItem('claro-tipo-id-producto', this.form.planSelected)
                        //this.form.pagoAceptado = true
                        this.$store.dispatch('checkMembership')                     
                        },
                        (error)=>{console.log(error);
                    })
                    
                    /*
                   
                    this.$store.dispatch('checkMembership')
                    */
                } catch (err) { 
                    this.loading = false;
                    this.modal = {
                        state: true,
                        description: 'Ha ocurrido un error <br><br> <span class="tx-16 tx-regular">favor de intentarlo mas tarde</span>',
                        type: 'error',
                    }
                    console.log(err) 
                }
            },
            handleAccept(){
                /*if (!this.form.pagoAceptado) {
                    this.modal.state = false;
                    return
                }*/
                this.modal.state = false;
                
                if(this.$store.state.resultadoTransaccion != ''){
                    /* this.$store.commit('modalAlertShopping') */
                    /* this.$router.push({ path: '/zona-premier' });      */           
                    this.$router.go(-1)     

                    setTimeout(() => {
                        window.location.reload()
                    }, 300);
                }
            },
            
            goToBack(){ 
                this.$router.go(-1)
            },
            checkedCode(){
                let params = {
                    codigo : this.form.codigo,
                    usuario_id: this.$store.state.userId
                }
                if (this.form.codigo != '') {
                    this.loading = true
                    axios.post(process.env.VUE_APP_SERVICE_URL  + `codigoReferenciados?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&`, params).then(res => {
                        console.log(res.data)

                        if(res.data.error === 1){
                            this.loading = false
                            this.modal = {
                                state: true,
                                description: 'Código Inválido',
                                type: 'error',
                            }
                            this.form.codigo = ''
                            this.successCode = false
                        } else {
                            this.loading = false
                            this.successCode = true

                            if (res.data.data.monto_porcentaje === 100) {
                                this.planAmount = 0
                                return
                            }

                            if(res.data.data.monto_porcentaje == 0) {
                                this.successCode = true
                                this.montoDeDescuento = 0
                                return
                            }

                            if (res.data.data.monto_porcentaje != 100 || res.data.data.monto_porcentaje != 0) {
                                this.planAmount = 1
                                return
                            }
                        } 
                    
                    }).catch(error => { console.log(error) })
                }
            },
            handleContinue(){
                this.$router.push({ path: localStorage.getItem('history-path') });
            }
        },
        computed: {
            showInputCode(){
                if (this.form.renovation){
                    return this.form.planSelected != 1 ? false : true 
                    return false
                } else { 
                    return this.form.planSelected != 1 ? false : true 
                }
            }
        },
/*         beforeRouteLeave (){
            window.history.pushState("object or string", "Title", "/"+window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split("?")[0]);
        }, */
        mounted(){
            this.getMemberships();

            document.querySelector("#renovation").addEventListener("change", function (event) {
                if (event.target.checked) {
                    window.history.pushState("object or string", "Title", "/"+window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split("?")[0]);
                } else {
                    let url = new URL(window.location.href);
                    url.searchParams.set('subs', 'false');
                    window.history.replaceState(null, null, url);
                }

                setTimeout(() => {
                    window.location.reload();
                }, 500);
            });

            let urlStr = window.location.href
            let url = new URL(urlStr);
            if (url.searchParams.get('subs')) {
                this.form.renovation = false
            }


/*             document.querySelector("#codigo").addEventListener("blur", function (event) {
                
                    this.loading = true
                    let params = {
                        codigo : this.form.codigo,
                        usuario_id: this.$store.state.userId
                    }   

                    if (this.form.codigo != '') {
                        axios.post(process.env.VUE_APP_SERVICE_URL  + `codigoReferenciados`, params).then(res => {
                            console.log(res.data);
                            if(res.data.error === 1){
                                this.loading = false
                                actions.disable();   
                                this.successCode = false
                            } else {
                                this.loading = false
                                actions.enable();   
                                if(res.data.data.monto_porcentaje === 0) {
                                    this.successCode = true
                                    this.montoDeDescuento = 0
                                    return
                                }

                                this.montoDeDescuento = this.calPercnt(this.planAmount, res.data.data.monto_porcentaje);
                                this.successCode = true
                            }                   
                        }).catch(error => { console.log(error) })
                    } else {
                        setTimeout(() => {
                            this.loading = false
                        }, 200);
                    }
                }); */

            const _this = this;
            this.loading = true
            /* PAYPAL BUTTON */
            setTimeout(() => {
                paypal_sdk.Buttons({
            style: {
                color:  'blue'
            },
            onInit(data, actions) {
                actions.disable();

                document.querySelector("#terms_conditions").addEventListener("change", function (event) {
                    if (event.target.checked) {
                        actions.enable();
                    } else {
                        actions.disable();
                    }
                });
            },
            onClick() {
                if (!_this.form.terms) {
                    _this.modal = {
                        state: true,
                        description: 'Debe de aceptar los términos y condiciones',
                        type: 'error',
                    }
                }    
                
                if (_this.form.codigo != '') {
                    if (!_this.successCode && _this.form.terms) { 
                        _this.modal = {
                            state: true,
                            description: 'Código Inválido',
                            type: 'error',
                        }
                    } 
                }

                _this.trackLog({ 
                    action: 'Clic botón comprar', 
                    medio: 'Botón', 
                    sectionId: 8, 
                    elementId: null, 
                })
            },
            createOrder: (data, actions) => {
               _this.getMemberships();
                _this.$store.state.tipoDeCompra = 'membresia'
                if (_this.form.codigo === '') _this.montoDeDescuento = 0
/*                 _this.planAmount = _this.planAmount - _this.montoDeDescuento; */
                
                if(_this.planAmount == 0){
                    _this.planAmount = 5;
                }

                return actions.order.create({
                    purchase_units: [{
                        description: this.nombreMembresia,
                        amount: {
                            value: Number(this.planAmount)
                        }
                    }]
                })
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture();

                _this.$store.state.resultadoTransaccion = JSON.stringify(order)

                try{
                    console.log(_this.$store.state.userId);
                    console.log(_this.form.planSelected);
                    console.log(_this.$store.state.renovacionMembresia);
                    console.log(JSON.stringify(order));

                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `pago-membresia?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${_this.$store.state.userId}&producto_id=${_this.form.planSelected}&renovacion=${_this.$store.state.renovacionMembresia}&metodo_pago_id=3&datos_transaccion=${JSON.stringify(order)}`);
                    console.log('Guardo info del producto: ' + res.data);
                } catch (err) { console.log(err) }

                this.modal = {
                    state: true,
                    description: 'Muchas gracias <br><br> <span class="tx-16 tx-medium">Tu pago ha sido exitoso</span>',
                    type: 'success',
                }                    

                _this.trackLog({ 
                    action: 'Compra realizada', 
                    medio: 'Botón', 
                    sectionId: 8, 
                    elementId: null, 
                })

                console.log('order: ' + JSON.stringify(order))
            },
            onError: err => console.log(err)
        })
                .render("#paypal")
                .catch((error) => console.error("failed to render the PayPal Button", error))

                this.loading = false
            }, 1000);

           setTimeout(() => {
                paypal_sdk.Buttons({
                    style: {
                        shape: 'rect',
                        color: 'gold',
                        layout: 'vertical',
                        label: 'subscribe'
                    },
                    onInit(data, actions) {
                        actions.disable();

                        document.querySelector("#terms_conditions").addEventListener("change", function (event) {
                            if (event.target.checked) {
                                actions.enable();
                            } else {
                                actions.disable();
                            }
                        });

                        document.querySelector("#codigo").addEventListener("blur", function (event) {
                            _this.loading = true
                            let params = {
                                codigo : _this.form.codigo,
                                usuario_id: _this.$store.state.userId
                            }   

                            if (_this.form.codigo != '') {
                                axios.post(process.env.VUE_APP_SERVICE_URL  + `codigoReferenciados`, params).then(res => {
                                    console.log(res.data);
                                    if(res.data.error === 1){
                                        _this.loading = false
                                        actions.disable();   
                                        _this.successCode = false
                                    } else {
                                        _this.loading = false
                                        actions.enable();   
                                        _this.montoDeDescuento = _this.calPercnt(_this.planAmount, res.data.data.monto_porcentaje);
                                        _this.successCode = true
                                    }                   
                                }).catch(error => { console.log(error) })
                            } else {
                                setTimeout(() => {
                                    _this.loading = false
                                }, 200);
                            }
                        });
                    },
                    onClick() {
                        if (!_this.form.terms) {
                            _this.modal = {
                                state: true,
                                description: 'Debe de aceptar los términos y condiciones',
                                type: 'error',
                            }
                        }    
                        
                        if (_this.form.codigo != '') {
                            if (!_this.successCode && _this.form.terms) { 
                                _this.modal = {
                                    state: true,
                                    description: 'Código Inválido',
                                    type: 'error',
                                }
                            } 
                        }

                        _this.trackLog({ 
                            action: 'Clic botón comprar', 
                            medio: 'Botón', 
                            sectionId: 8, 
                            elementId: null, 
                        })
                    },
                    createSubscription: function(data, actions) {
                        _this.getMemberships();
                        _this.$store.state.tipoDeCompra = 'membresia'
                        if (_this.form.codigo === '') _this.montoDeDescuento = 0
                        _this.planAmount = _this.planAmount - _this.montoDeDescuento;
                        
                        if(_this.planAmount == 0){
                            _this.planAmount = 1;
                        }
                        return actions.subscription.create({
                            purchase_units: [{
                                description: this.nombreMembresia,
                                amount: {
                                    value: Number(this.planAmount)
                                }
                            }],
                            plan_id: process.env.VUE_APP_PAYPAL_PLAN_ID
                            //plan_id: 'P-77584606RR389805VM5Q42WQ' // zuggy
                            //plan_id: 'P-8UD27591W8936815XM5Q67TY' pedro
                            //plan_id: 'P-836930665U629734CM57MCFQ' // sandbox 
                        });
                    },
                    onApprove: async (data, actions) => {
                        const date = new Date();
                        let obj = {
                            "id": data.orderID,
                            "intent": "CAPTURE",
                            "status": "SUSCRIPCION",
                            "purchase_units": [
                                {
                                    "reference_id": "default",
                                    "amount": {
                                        "currency_code": "MXN",
                                        "value": "89.00"
                                    },
                                    "payee": {
                                        "email_address": "sb-0okto33742001@business.example.com",
                                        "merchant_id": "SUBSCRIPTION"
                                    },
                                    "description": "Mensual",
                                    "soft_descriptor": "PAYPAL *TEST STORE",
                                    "shipping": {
                                        "name": {
                                            "full_name": "Zuggy Subscription"
                                        },
                                    },
                                    "payments": {
                                        "captures": [
                                            {
                                                "id": data.orderID,
                                                "status": "COMPLETED",
                                                "amount": {
                                                    "currency_code": "MXN",
                                                    "value": "89.00"
                                                },
                                                "final_capture": true,
                                                "seller_protection": {
                                                    "status": "ELIGIBLE",
                                                    "dispute_categories": [
                                                        "ITEM_NOT_RECEIVED",
                                                        "UNAUTHORIZED_TRANSACTION"
                                                    ]
                                                },
                                                "create_time": date,
                                                "update_time": date
                                            }
                                        ]
                                    }
                                }
                            ],
                            "payer": {
                                "name": {
                                    "given_name": "Zuggy",
                                    "surname": "Subscription"
                                },
                                "email_address": "client-zuggy@payex.com",
                                "payer_id": data.subscriptionID,
                                "address": {
                                    "country_code": "MX"
                                }
                            },
                            "create_time": date,
                            "update_time": date,
                            "links": [
                                {
                                    "href": "",
                                    "rel": "",
                                    "method": ""
                                }
                            ]
                        }

                        //actions.subscription: trae el id para ver el detalle de la suscripcion
                        console.log('SUB ID: ' + JSON.stringify(data.subscriptionID));
                        console.log(data.payer);
                        console.log(data);
                        console.log(obj);

                        _this.$store.state.resultadoTransaccion = JSON.stringify(data)

                        try{
                            console.log(_this.$store.state.userId);
                            console.log(_this.form.planSelected);
                            console.log(_this.$store.state.renovacionMembresia);
                            console.log(JSON.stringify(data));
                            
                            this.$store.commit('setPayPalSubId', actions.subscription)

                            const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `pago-membresia?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${_this.$store.state.userId}&producto_id=${_this.form.planSelected}&renovacion=${_this.$store.state.renovacionMembresia}&metodo_pago_id=3&datos_transaccion=${JSON.stringify(obj)}`);
                            console.log('Guardo info del producto: ' + res.data);
                        } catch (err) { console.log(err) }

                        this.modal = {
                            state: true,
                            description: 'Muchas gracias <br><br> <span class="tx-16 tx-medium">Tu pago ha sido exitoso</span>',
                            type: 'success',
                        }                    

                        _this.trackLog({ 
                            action: 'Compra realizada', 
                            medio: 'Botón', 
                            sectionId: 8, 
                            elementId: null, 
                        })

                        console.log('order: ' + JSON.stringify(data))
                    },
                    onError: err => console.log(err)
                })
                .render('#paypal_subs')
                .catch((error) => console.error("failed to render the PayPal Buttons", error));

                this.loading = false
           }, 1000);

            this.trackLog({ 
                action: 'Ingreso comprar membresía', 
                medio: 'Botón', 
                sectionId: 8, 
                elementId: null, 
            })

            sdkclaro.getInstance().switchGoBackButton(true)
            console.log("renovacion: " + this.$store.getters['renovacionMembership'])
        },
    }
</script>
<template>
    <div class="introduction-view welcome-view pt-10 sm:pt-20 pb-15">
        <div class="container">
            <img class="flex mx-auto mb-7" src="@/assets/images/views/welcome/title-new.png" alt="">

            <p class="tx-center cursor-pointer tx-underline" @click="handleContinue()">
                Adquirir en otro momento
            </p>

            <div class="box box--white rounded-14 px-4 py-6 sm:py-10 sm:p-10 mb-4">
                <!-- <p class="tx-center tx-purple-2 tx-semibold mb-7 mx-auto bordev" style="max-width: 649px;" v-html="toDesc.plan"></p> -->
                <p class="tx-center tx-purple-2 tx-semibold mb-7 mx-auto" style="max-width: 649px;">
                    Obtén 1 MES GRATIS y recuerda que puedes cancelar tu suscripción mensual cuando quieras. A partir del segundo mes verás reflejado un cobro mensual por $89.00 MXN o si lo prefieres, obtén gratis 2 meses al comprar tu membresía por un año.
                </p>

                <table class="w-fit table-collapse mx-auto mb-2">
                    <tbody>
                        <tr v-for="(plan, idx) in memberships" :key="idx" :class="form.renovation && plan.id > 1 ? 'no-events opacity-04' : ''">
                            <td class="tx-center pr-4">
                                <input v-if="$store.getters['renewalMembership'] == 1" @click="montoSeleccionado(plan.monto, plan.nombre)" class="form-radio-input" type="radio" name="plans" :value="plan.id" :id="plan.id" v-model="form.planSelected"/>
                                <input v-else @click="montoSeleccionado(plan.monto_promocion, plan.nombre)" class="form-radio-input" type="radio" name="plans" :value="plan.id" :id="plan.id" v-model="form.planSelected"/>
                            </td>
                            <td class="">
                                <label v-if="$store.getters['renewalMembership'] == 1" @click="montoSeleccionado(plan.monto, plan.nombre)" class="form-radio-label tx-purple tx-12 md:tx-16 tx-semibold" :for="plan.id">
                                    {{ plan.promocion }}
                                </label>
                                <label v-else @click="montoSeleccionado(plan.monto_promocion, plan.nombre)" class="form-radio-label tx-purple tx-12 md:tx-16 tx-semibold" :for="plan.id">
                                    {{ plan.promocion }}
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td class="tx-center">
                                
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="w-fit mx-auto mb-7">
                    <tbody>
                        <tr>
                            <td class="tx-center pr-4">
                                <label class="form-switch">
                                    <input class="form-switch-input" type="checkbox" id="terms_conditions" v-model="form.terms">
                                    <span class="form-switch-slider"></span>
                                </label>
                            </td>
                            <td>
                                <button class="btn tx-purple-2" style="text-decoration: underline; text-align: left; padding: 0; background: initial; border: 0; box-shadow: none;" @click="terms(`terminos-init/`)">
                                    Acepta Términos y Condiciones
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="tx-center pr-4">
                                <label class="form-switch">
                                    <input class="form-switch-input" type="checkbox" id="renovation" v-model="form.renovation">
                                    <span class="form-switch-slider"></span>
                                </label>
                            </td>
                            <td>
                                <!-- <div class="tx-purple-2 tx-14" v-html="toLabel.plan"></div> -->
                                <div class="tx-purple-2 tx-14">
                                    Acepto renovar automáticamente mi suscripción mensual una vez transcurridos los 30 días de prueba.
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- PROMOTIONAL CODE -->
                <input class="form-input form-input--cg mt-2 max-w-90 mx-auto" style="background-color: rgba(255, 255, 255, 0.3); border-radius: 10px;" placeholder="Ingresa tu código promocional" type="text" id="codigo" v-model.trim="form.codigo" @blur="checkedCode()" v-show="showInputCode">

                <!--<my-button class="mb-3" label="Volver" action="Salir compra de membresía" :sectionId="8" @clicked="goToBack()"/>-->
                <!-- <my-button classBtn="btn--purple rounded-10 h-8 max-w-40 mx-auto" label="Continuar" action="Continuar registro" :sectionId="8" @clicked="purchaseMembership()"/> -->
            </div>

            <img class="flex mx-auto mb-5" src="@/assets/images/pay-method.png" alt="">

            <!-- PAY -->
            <div class="flex tx-center justify-center">
                <div class="w-100pr max-w-100 z-0" id="paypal" @clicked="purchaseProduct()" style="background-color:white; padding:5px; border-radius:5px;" v-show="!form.renovation"></div>
                <div class="w-100pr max-w-100 z-0" id="paypal_subs" @clicked="purchaseProduct()" style="background-color:white; padding:5px; border-radius:5px;" v-show="form.renovation"></div>
            </div>

            <!-- LOADER -->
            <div class="overlay fixed grid place-items-center t-0 l-0 w-100pr h-100pr z-10" v-if="loading">
                <div class="loader-spinner"></div>
            </div>

            <!-- MODAL TERMS -->
            <transition name="fade">
                <modal-alert v-if="modal.state" @close="handleAccept()">
                    <div class="pt-6 pb-4 px-4">
                        <p class="tx-22 tx-bold tx-center mb-9" v-html="modal.description"></p>
                        <button class="btn mx-auto flex" :class="modal.type === 'success' ? 'btn--success' : 'btn--danger'" @click="handleAccept()">
                            Aceptar
                        </button>
                    </div>
                </modal-alert>
            </transition>

            <!-- MODAL -->
            <transition name="fade">
                <!-- <modal-alert v-if="modal.state" @close="handleAccept()">-->
                <modal-alert v-if="$store.getters['paymentModalState']" @close="handleAccept()">
                     <div class="p-4">
                        <p class="tx-24 tx-bold tx-center mb-9" v-html="modal.description"></p>
                        <button class="btn mx-auto flex" :class="modal.type === 'success' ? 'btn--success' : 'btn--danger'" @click="handleAccept()">
                            Aceptar
                        </button>
                    </div>
                </modal-alert>
            </transition>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .btn--success{
        border-radius: 10px;
    }

    #codigo:focus {
        outline: none !important;
        border:1px solid red;
    }

    .box--white{
        background: rgba($color: #fff, $alpha: 0.8);
    }

    .form-input--cg{
        background: rgba($color: #41297C, $alpha: 0.7)!important;

        &::placeholder{
            text-align: center;
        }
    }

    .overlay{
        background-color: rgba($color: #000000, $alpha: 0.6);
    }
</style>