<script>
    import axios from "axios"
    
    import BurgerBtn from '../ui/BurgerBtn.vue'
    import ControlsBackAndHome from './ControlsBackAndHome.vue'
    import NavBarMenu from './NavBarMenu.vue'
    import ModalIke from '../ModalIke.vue'
    import ModalVideoPlayer from "../ModalVideoPlayer.vue"

    export default {
        components: { ControlsBackAndHome, BurgerBtn, NavBarMenu, ModalIke, ModalVideoPlayer },
        props: {
            burgerBtn: Boolean
        },
        data: () => ({
            ikeMembership: 0,
            modalForm: false,
            modal: false,
            routes: [
                { label: 'Zona Premier', path: '/zona-premier' },
                { label: 'Tienda', path: '/tienda' },
                { label: 'Comunidad', path: '/comunidad' },
                { label: 'Recompensas', path: '/recompensas' },
                { label: 'Asistencia', path: '/asistencia' },
                { label: 'FAQ', path: '/preguntas-frecuentes' },
               /*  { label: 'Soporte técnico', path: '/soporte-tecnico' }, */
            ],
            mobileMenu: false
        }),
        computed: {
            currentRouteName() {
                return this.$route.fullPath;
            }
        },
        methods: {
            changeRoute(path) {
                this.$router.push({ path: path });
            },
            toggleMenu(){ this.mobileMenu = !this.mobileMenu; },
            goToBack(){ history.back() },
            async ikeValidation() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `ike-vigencia?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}`)
                    this.ikeMembership = res.data.error
                } catch (err) { console.log(err); }
            },
            handleSupport(){
                this.ikeValidation()
                if (this.ikeMembership === 1) { 
                    this.modalForm = !this.modalForm 
                    return
                }  
            },
            toggleVideo(){
                this.modal = !this.modal
            }
        },
        mounted() {
            this.ikeValidation()
            this.$store.dispatch('checkProfile')
        }
    }
</script>
<template>
    <header class="main-header h-20 fixed t-0 l-0 w-100pr z-10">
        <div class="container flex align-center h-100pr">

            <!-- LOGO -->
            <router-link to="/" aria-current="page">
                <div class="w-14">
                    <img class="header-logo w-14 block" src="@/assets/images/logo.svg" alt="">
                </div>
            </router-link>

            <!-- BTN MENU -->
            <burger-btn class="w-9 lg:hidden r-0 mb-1 ml-auto"/>

            <!-- NAV BAR MENU DOWN LG -->
            <transition name="nav-bar">
                <nav-bar-menu @checkIke="handleSupport()" :ikeMembership="ikeMembership" v-show="$store.getters['navBarMenuOpen']"/> 
            </transition>
            
            <!-- NAV UP LG -->
            <nav class="main-nav ml-auto hidden lg:block">
                <ul class="main-nav-list flex tx-medium lg:tx-8 xl:tx-12 bullet-none spacing-0">
                    <li class="main-nav-item mx-2" v-for="(route, idx) in routes" :key="idx">
                        <router-link class="main-nav-link tx-uppercase tx-semibold tx-italic hover:tx-primary-1 tx-whitespace-nowrap" active-class="active" :to="route.path" exact>
                            {{ route.label }}
                        </router-link>
                    </li>
                    <li class="main-nav-item mx-2 relative">
                        <router-link class="main-nav-link tx-uppercase tx-semibold tx-italic hover:tx-primary-1 tx-whitespace-nowrap" active-class="active" to="/soporte-tecnico" exact>
                            Guía Técnica
                        </router-link>
                        <layer-restrict v-if="!$store.getters['isMember']"/>
                        <div class="t-0 l-0 absolute w-100pr h-100pr" @click="handleSupport()" v-if="ikeMembership != 0"></div>
                    </li>
                    <li class="main-nav-item mx-2 relative">
                        <div class="main-nav-link tx-uppercase tx-semibold tx-italic hover:tx-primary-1 tx-whitespace-nowrap" @click="toggleVideo()">
                            Ver video
                        </div>
                    </li>
                </ul>
            </nav>

            <!-- PROFILE -->
            <div class="ml-4 cursor-pointer relative" v-if="$store.getters['logeddIn']">
                <div class="hidden lg:flex align-center" @click="changeRoute($store.getters['profileCompleted'] ? '/mi-perfil' : '/crear-perfil')">
                    <img src="@/assets/images/layout/icon-user.svg" alt="">
                    <span class="tx-primary-1 tx-12 tx-medium block mt-1 ml-2">
                        {{ $store.getters['getUserLogData'].nombre }}
                    </span>
                </div>

                <!-- <layer-restrict v-if="!$store.getters['isMember']"/> -->
            </div>
            <router-link class="main-nav-link tx-12 tx-uppercase tx-semibold tx-italic hover:tx-primary-1 tx-whitespace-nowrap ml-4" to="/login" v-else>
                <img src="@/assets/images/layout/icon-user.svg" alt="">
                Login
            </router-link>
        </div>

        <transition name="fade">
            <modal-ike v-if="modalForm" @close="handleSupport()" @alertClose="modalForm = false"></modal-ike>
            <modal-video-player @close="toggleVideo()" v-if="modal"/>
        </transition>
    </header>
</template> 
<style lang="scss" scoped>
    .main-header {
        background-color: rgba($color: #000000, $alpha: 0.45);
    }

    .main-nav-link.active{
        color: #B55CFF!important;
    }
</style>
