<script>
    import axios from "axios"
    import ModalAlert from '@/components/ModalAlert.vue';

    export default {
    components: { ModalAlert },
        data: () => ({

        }),
        methods: {

        },
        mounted(){

        }
    }
</script>
<template>
    <div class="community-view relative pb-6">
        <div class="container pb-10">
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-16">
                <span>Guía Técnica</span>
            </div>

            <img class="mb-8 mx-auto flex" src="@/assets/images/views/support.png" alt="">
            
            <div class="box box--purple bg-purple tx-center mx-auto px-10 sm:px-15 py-5 rounded-10">
                <p class="tx-white tx-center tx-strong mb-0">
                    Llama desde el número que te registraste
                </p>
                <a class="tx-bold tx-22 sm:tx-32" href="tel:+525590633558">
                    55 9063 3558
                </a>
            </div>
        </div>
    </div>    
</template>
<style lang="scss" scoped>
    .box--purple{
        max-width: 530px;

        a{
            color: #B51FBC;
        }
    }
</style>