<script>
    import axios from "axios"

    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import NavBarMenu from '@/components/layout/NavBarMenu.vue'
    import * as sdkclaro from "@claro/sdkclaro";
    import MyButton from "@/components/ui/MyButton.vue";
    
    export default {
        components: { HeaderComponent, NavBarMenu, MyButton },
        data: () => ({
            render: false,
            zuggyBenefits: [],
            specialEvent: {},
            specialsEvents: [],
            bannerVive: null,
            swiperIkee: {
                spaceBetween: 20,
                allowTouchMove: true,
                slidesPerView: 1.5,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                breakpoints: {
                    640: {
                        slidesPerView: 2.2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                },
            },
            swiperBig: {
                allowTouchMove: true,
                slidesPerView: 1.2,
                centeredSlides: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                effect: 'coverflow',
                coverflowEffect: {
                    rotate: 1,
                    stretch: 235,
                    depth: 90,
                    modifier: 1,
                    slideShadows: true,
                },
                breakpoints: {
/*                     640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    }, */
                    1024: {
                        slidesPerView: 1.8,
                    },
                },
            },
            dataUser: {},
            modalPoints: false,
            modalNoProfile: false
        }),
        methods: {
            async getZuggyBenefits (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `beneficios-zuggy?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.zuggyBenefits = res.data;
                } catch (err) { console.log(err) }
            },
            async getSpecialEvent (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `eventos-especiales?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    console.log('getSpecialEvent')
                    console.log(res.data)
                    this.specialsEvents = res.data;
                } catch (err) { console.log(err) }
            },
            async getBannerVive(){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `banner-vive-plus?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.bannerVive = res.data;
                } catch (err) { console.log(err) }
            },
            goToSpecialEvent(){
                this.trackLog({ 
                    action: 'Click evento especial', 
                    medio: 'Banner', 
                    sectionId: 9, 
                    elementId: null, 
                })

                window.open(this.specialEvent.url, '_blank');
            },
            goToBenefit(path){
                console.log("Click en beneficio zuggy")
                this.trackLog({ 
                    action: 'Click beneficios zuggy', 
                    medio: 'Banner', 
                    sectionId: 9, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
            goToFeaturedUrl(url){
                this.trackLog({ 
                    action: 'Click eventos destacados', 
                    medio: 'Banner', 
                    sectionId: 9, 
                    elementId: null, 
                })

                window.open(url, '_blank');
            },
            goToNews(path){
                this.trackLog({ 
                    action: 'Click  Experiencias Zuggy Club Gamers', 
                    medio: 'Banner', 
                    sectionId: 2, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
            goToVivePlus(path){
                this.trackLog({ 
                    action: 'Click beneficios zuggy', 
                    medio: 'Banner', 
                    sectionId: 9, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
            async getProfile() {
                try {
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `perfilamiento?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}`)
                    this.dataUser = res.data[0];
                } catch (err) { console.log(err) }
            },
            toggleModalPoints(){
                this.modalPoints = !this.modalPoints
            },
            toggleModalNoProfile(){
                this.modalNoProfile = !this.modalNoProfile
            },
            handlePoints(){
                this.$store.getters['profileCompleted'] ? this.toggleModalPoints() : this.toggleModalNoProfile()
            },
            handleCreateProfile(){
                this.$router.push({ path: '/crear-perfil' })
            }
        },
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true);
            this.getZuggyBenefits();
            this.getSpecialEvent();
            this.getBannerVive();
            this.getProfile()

            setTimeout(() => this.render = true, 0);
            
            this.trackLog({ 
                action: 'Ingreso a recompensas', 
                medio: 'Botón', 
                sectionId: 9, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="rewards-view">
        <div class="container relative">
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>PROMOCIONES DEL MES</span>
            </div>
            <!-- <div class="h-30 sm:h-80 rounded-10 mb-6 bg-cover bg-center bg-no-repeat" :style="{ 'background-image': 'url(' + bannerVive.imagen + ')' }" @click="goToVivePlus(`/viveplus`)">
            </div> -->
            <div class="rounded-10 mb-6 cursor-pointer" @click="goToVivePlus(`/viveplus`)">
                <img class="rounded-10" :src="bannerVive.imagen" alt="">
            </div>

            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>BENEFICIOS ZUGGY CLUB GAMERS</span>
            </div>
            <!-- SWIPER -->
            <swiper class="swiper swiper-ikee mb-6" :options="swiperIkee" ref="swiperZuggy" v-if="render">
                <swiper-slide v-for="(item, idx) in zuggyBenefits" :key="idx">
                    <div class="item-layer relative flex align-center w-100pr relative bg-center bg-cover bg-no-repeat rounded-t-l-20 rounded-b-r-20 p-4 z-0 overflow-hidden" @click="goToBenefit(`/recompensa/${item.id}`)">
                        <img class="h-100pr rounded-t-l-20 rounded-b-r-20" :src="item.imagen" alt="">
                        <div>
                            <h3 class="tx-14 mb-0 tx-medium">
                                {{ item.titulo }}
                            </h3>
                            <p class="tx-12 mb-0 max-h-3 overflow-hidden">
                                {{ item.sinapsis }}
                            </p>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <!-- LAYER RESTRICT -->
            <layer-restrict v-if="!$store.getters['isMember']"/>
        </div>
        <div class="container pb-15">
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>EXPERIENCIAS ZUGGY CLUB GAMERS</span>
            </div>

            <!--<div class="item-event flex align-end w-100pr h-50 relative bg-center bg-cover bg-no-repeat rounded-10 border-1 border-white p-4 z-0 overflow-hidden" :style="{ 'background-image': 'url(' + specialEvent.imagen + ')' }" @click="goToSpecialEvent()">
                <div class="max-w-75pr">
                    <h3 class="tx-14 mb-1 tx-medium">
                        {{ specialEvent.titulo }}
                    </h3>
                    <p class="tx-12 mb-0">
                        {{ specialEvent.sinapsis }}
                    </p>
                </div>
            </div>-->

            <swiper class="swiper swiper-big mb-6" :options="swiperBig" ref="swiperBig">
                <swiper-slide v-for="(item, idx) in specialsEvents" :key="idx">
                    <!--a @click="goToFeaturedUrl(item.url)">-->
                        <div class="item-event flex align-center w-100pr relative bg-center bg-cover bg-no-repeat rounded-10 p-4 z-0 overflow-hidden" @click="goToNews(`/experiencia/${item.id}/titulo`)">
                            <img class="rounded-20" :src="item.imagen" alt="">
                            <div class="max-w-75pr">
                                <h3 class="tx-14 mb-1 tx-medium">
                                    {{ item.titulo }}
                                </h3>
                                <p class="tx-12 mb-0 h-8 overflow-hidden">
                                    {{ item.sinapsis }}
                                </p>
                            </div>
                        </div>
                    <!--</a>-->
                </swiper-slide>
            </swiper>

            <!-- POINTS -->
<!--             <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>PUNTOS ZUGGY</span>
            </div>

            <div @click="handlePoints()">
                <img class="block mx-auto cursor-pointer" src="@/assets/images/views/btn-points.png" alt="">
            </div> -->
        </div>

        <transition name="fade">

            <!-- MODAL NO PROFILE -->
            <div class="modal flex align-center z-101" v-if="modalNoProfile">
                <div class="modal-lightbox lightbox-ike w-100pr max-w-100 py-6 px-3 bg-center bg-cover bg-no-repeat rounded-10" :style="{'background-image' : `url(${require('@/assets/images/layout/bg-modal-restrict.png')})`}">
                    <div class="modal-close tx-white tx-52 mx-auto -mb-17" @click="toggleModalNoProfile()">
                        &times;
                    </div>

                    <p class="tx-22 tx-center mt-5 my-10">
                        Completa tu perfil para conocer <br> tu saldo en punto Zuggy
                    </p>
            
                    <my-button label="Completar perfil" @clicked="handleCreateProfile()"/>
                </div>
            </div>

            <!-- MODAL POINTS -->
            <div class="modal flex align-center z-10" v-if="modalPoints">
                <div class="modal-lightbox lightbox-ike w-100pr max-w-100 p-4 bg-center bg-cover bg-no-repeat rounded-10" :style="{'background-image' : `url(${require('@/assets/images/layout/bg-modal-alert.png')})`}">
                    <div class="modal-close tx-white tx-52 mx-auto -mb-17" @click="toggleModalPoints()">
                        &times;
                    </div>
                    
                    <img class="absolute t-0 l-0 r-0 mx-auto -mt-20" src="@/assets/images/layout/icon-alert.svg" alt="">

                    <p class="tx-24 tx-bold tx-center mt-5">
                        Tus puntos zuggy son:
                    </p>

                    <p class="tx-36 tx-center tx-medium py-5">
                        {{ dataUser.puntos }}
                    </p>

                    <p class="tx-16 tx-center mt-5" v-if="dataUser.puntos === 0">
                        Realiza comprar para obtener puntos
                    </p>

                    <my-button classBtn="btn--success" label="Aceptar" @clicked="toggleModalPoints()"/>
                </div>
            </div>
        </transition>
    </div>
</template>
<style lang="scss" scoped>

    .item-event::after,
    .next-event::after{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        //background-image: linear-gradient(rgba(#000, 0), rgba(#000, 0.2), rgba(#000, 1));
        z-index: -1;
    }

    .swiper-big{
        .swiper-slide{
            opacity: 0!important;
        }

        .swiper-slide-next,
        .swiper-slide-prev{
            opacity: 0.8!important;
        }

        .swiper-slide-active{
            opacity: 1!important;
        }
    }
</style> 