<script>
    import axios from "axios"
    import LoadingScreen from './components/layout/LoadingScreen.vue';
    import RestrictModal from './components/RestrictModal.vue';
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { LoadingScreen, RestrictModal },
        data: () => ({ isLoading: true }),
        mounted() { 
            this.$store.dispatch('getPlatformId')
            this.$store.dispatch('checkMembership')
            this.$store.dispatch('checkProfile')

            console.log('mounted');

            setTimeout(() => this.isLoading = false, 400) 
            //console.logprocess.env.VUE_APP_SERVICE_URL  + this.$store.getters['userId'])
            //console.log(this.$store.state.userId)

            console.log('LOCATION' + window.location.pathname );
            localStorage.setItem('history-patch', window.location.pathname)
            
        },
        updated() {
            if (window.location.pathname === '/login') return
            if (window.location.pathname === '/comprar-membresia') return
            localStorage.setItem('history-path', window.location.pathname)
        },
        beforeCreate(){
            /* this.$store.commit('setUserId'); */
        },
    }
</script>
<template>
    <div class="club-gamer-app" id="app">

<!--         <transition name="fade">
            <loading-screen v-show="isLoading"/>  
        </transition>     
        <transition name="slide-fade">
            <router-view v-show="!isLoading"/>
        </transition> -->

        <router-view/>

        <!-- RESTRICT MODAL -->
        <transition name="fade">
            <restrict-modal v-if="$store.getters['restrictModalState']"/>
        </transition>
    </div>
</template>