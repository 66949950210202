<script>
    import axios from "axios"
    import MyButton from '@/components/ui/MyButton.vue';

    import { useVuelidate } from '@vuelidate/core'
    import { required, email } from '@vuelidate/validators'
    import ModalAlert from '../ModalAlert.vue';
    import FormRecover from './FormRecover.vue';

    export default {
        components: { MyButton, ModalAlert, FormRecover },
        setup () {
            return { 
                v$: useVuelidate(),
                v2$: useVuelidate(),
            }
        },
        data: () => ({
            userEmail: '',
            userPassword: '',
            loading: false,
            recover: false,
            modal: {
                state: false,
                description: '',
                type : ''
            },
        }),
        validations: () => ({
            userEmail: { required, email },
            userPassword: { required },
        }),
        computed: {
            user () {
                return {
                    "correo_electronico"      : this.userEmail,
                    "contrasena"              : this.userPassword,
                }
            },
            userRecover () {
                return {
                    "correo_electronico"      : this.userEmailRecover,
                }
            },
        },
        methods: {
            async submitForm(){
                const result = await this.v$.$validate()
                if (!result) return

                this.loading = true
                axios.post(process.env.VUE_APP_SERVICE_URL  + `iniciar_sesion?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`, this.user).then(res => {
                    if (res.data.error === 1) {
                        this.loading = false;
                        this.modal = {
                            state: true,
                            description: 'Ha ocurrido un error <br><br> <span class="tx-16 tx-regular">usuario o constraseña incorrecta</span>',
                            type: 'error',
                        }

                        return
                    }

                    this.$store.commit('setLog')
                    this.$store.commit('setUserLogData', res.data.data);
                    localStorage.setItem('user-paypal-id', res.data.data.claro_pay_id)
                    this.$store.state.userId = res.data.data.claro_pay_id

                    this.$store.dispatch('checkProfile')
                    this.$store.dispatch('checkMembership')

                    setTimeout(() => {
                        if (!this.$store.getters['isMember']) {
                            this.$router.push({ path: '/comprar-membresia' });
                            return
                        }

                        this.$router.push({ path: localStorage.getItem('history-path') });

                        /* if (this.$store.state.membership) this.$router.replace({ path: '/zona-premier' })
                        this.$router.replace({ path: '/zona-premier' }) */
                    }, 800);

                }).catch(error => {
                    this.loading = false;
                    this.modal = {
                        state: true,
                        description: 'Ha ocurrido un error <br><br> <span class="tx-16 tx-regular">favor de intentarlo de nuevo</span>',
                        type: 'error',
                    }
                    console.log(error); 
                })
            },
            handleAccept(){
                window.location.reload();
            }
        }
    }
</script>

<template>
    <div>
        <!-- EMAIL -->
        <div v-if="!recover">
            <div class="form-group mb-4">
                <label class="form-label form-label--cg tx-10" for="">Correo electrónico</label>
                <input class="form-input form-input--cg" type="text" placeholder="Email" maxlength="50" v-model.trim="userEmail" pattern="[a-z]{1,50}">
            </div>
            <div style="color: red;" class="tx-error tx-14 opacity-1 tx-center mb-4 -mt-3" v-if="v$.userEmail.$error">
                Favor de escribir un correo electrónico válido
            </div>
            <!-- PASSWORD -->
            <div class="form-group mb-4">
                <label class="form-label form-label--cg tx-10" for="">Contraseña</label>
                <input class="form-input form-input--cg" type="password" placeholder="Contraseña" maxlength="50" v-model.trim="userPassword" pattern="[a-z]{1,50}">
            </div>
            <div style="color: red;" class="tx-error tx-14 opacity-1 tx-center mb-4 -mt-3" v-if="v$.userPassword.$error">
                Favor de llenar este campo
            </div>

            <my-button class="btn--white max-w-38 mx-auto mb-3" label="Iniciar sesión" path="/zona-premier" @clicked="submitForm()"/>
        </div>
        
        <!-- RECOVER -->
        <form-recover v-else/>
        
        <p class="tx-center tx-purple-3 tx-bold cursor-pointer tx-underline" @click="recover = !recover, $emit('recover')">
            {{ !recover ? 'Recuperar contraseña' :  'Iniciar Sesión' }}
        </p>
        <!-- LOADER -->
        <div class="overlay fixed grid place-items-center t-0 l-0 w-100pr h-100pr z-10" v-if="loading">
            <div class="loader-spinner"></div>
        </div>

        <!-- MODAL -->
        <transition name="fade">
            <modal-alert v-if="modal.state" @close="handleAccept()">
                <div class="p-4">
                    <p class="tx-22 tx-bold tx-center mb-9" v-html="modal.description"></p>
                    <button class="btn mx-auto flex" :class="modal.type === 'success' ? 'btn--success' : 'btn--danger'" @click="handleAccept(modal.type)">
                        Aceptar
                    </button>
                </div>
            </modal-alert>
        </transition>
    </div>
</template>
<style lang="scss" scoped>  
    .overlay{
        background: rgba($color: #000, $alpha: 0.4)
    }

    .tx-error{
        color: rgb(255, 69, 69)!important;
    }

    .form-input--cg,
    .form-label--cg{
        font-size: 14px!important;
    }

    .form-label--cg{
        padding: 5px 12px;
    }

    .form-input--cg{
        height: 30px;

        &::placeholder{
            font-size: 14px;
        }
    }
</style>