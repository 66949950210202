<script>
    import axios from 'axios';
    import ModalAlert from '@/components/ModalAlert.vue';

    export default {
  components: { ModalAlert },
        data: () => ({
            current: {},
            scheduledEvent: false,
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            modal: false,
        }),     
        methods: {
            async getEvent (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `detalle-evento?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&id=` + this.$route.params.id);
                    this.current = res.data[0]
                } catch (err) { console.log(err) }
            },
            async handleScheduleEvent(){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `registro-evento?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=` + this.$store.getters['userId'] +'&evento_id=' + this.$route.params.id);
                    this.getUsrEvents();
                } catch (err) { console.log(err) }
            },
            async getUsrEvents (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `mis-eventos?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=` + this.$store.getters['userId']);
                    this.scheduledEvent = res.data.some(event => event.id == this.$route.params.id)
                } catch (err) { console.log(err) }
            },
            schedule(){
                this.modal = true

                this.trackLog({ 
                    action: 'Agendar', 
                    medio: 'Botón', 
                    sectionId: 1, 
                    elementId: this.$route.params.id, 
                })
            },
            handleConfirm(action){    
                this.trackLog({ 
                    action: action, 
                    medio: 'Botón', 
                    sectionId: 1, 
                    elementId: this.$route.params.id, 
                })

                if (action == 'Cancelar agendar') {
                    this.modal = false
                    return
                }

                this.modal = false
                this.handleScheduleEvent();
            },
            buscando(){
                var info = document.getElementById("info-c");
                var span_inco = info.getElementsByTagName("p");
                var span = info.getElementsByTagName("span");
 
                for (let item of span_inco) {
                    item.style.color = "";
                }
                for (let item of span) {
                    item.style.color = "";
                }
            }
            
        },
        computed: {
            date( ){
                return this.months[new Date(this.current.fecha).getMonth()] + ' / ' + new Date(this.current.fecha).getDate()
            },
            hours(){
                return new Date(this.current.fecha).getHours()+':'+(new Date(this.current.fecha).getMinutes() < 10 ? '0' : '') + new Date(this.current.fecha).getMinutes()
            }
        },  
        mounted() {
            this.getEvent();
            this.getUsrEvents();

            this.trackLog({ 
                action: 'Ingreso a detalle de evento', 
                medio: 'Banner', 
                sectionId: 1, 
                elementId: this.$route.params.id, 
            })

            //setTimeout(function () { this.buscando(); }.bind(this), 100)
        }
    }
</script>

<template>
    <div class="event-detail-view pb-10">
        <div class="container">
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>{{ current.titulo }}</span>
            </div>
            <!-- <div class="relative bg-center bg-cover bg-no-repeat h-74 w-100pr rounded-10 mb-5 mx-auto" :style="{ 'background-image': 'url(' + current.imagen + ')' }">
            </div> -->
            <img class="mb-5 mx-auto rounded-16" :src="current.imagen" alt="">

            <!-- BODY -->
            <div class="box p-0">
                <div class="box-title py-2 px-4 tx-center tx-20 tx-bold rounded-t-10" v-html="current.sinapsis"></div>
                <div class="box-body forum-body tx-14 mb-6 p-9 rounded-b-10" v-html="current.descripcion"></div>
            </div>

            <!-- DATE -->
            <p class="tx-20 tx-bold mb-0" v-html="date"></p>
            <p class="tx-20 tx-bold">
                {{ hours }} Hrs.
            </p>

            
            <div class="w-fit relative mx-auto">
                <div class="tx-center tx-18 relative tx-medium mt-16" v-if="scheduledEvent">
                    ¡Ya has agendado <br> este evento!
                </div>
                <div class="tx-center w-fit mx-auto relative cursor-pointer" @click="schedule()" v-else>
                    <div class="relative w-fit mx-auto flex flex-column align-center justify-center mb-1">
                        <img class="w-12" src="@/assets/images/layout/icon-plus.png" alt="">
                    </div>
                    <span class="tx-14 tx-medium">
                        Agendar
                    </span>
                </div>

                <!-- LAYER RESTRICT
                <layer-restrict class="w-fit" :type="2" v-if="!$store.getters['profileCompleted']"/>-->
                <layer-restrict v-if="!$store.getters['isMember']"/>
            </div>
        </div>

        <!-- MODAL -->
        <transition name="fade">
            <modal-alert v-if="modal" @close="modal = false">
                <div class="py-4 px-1">
                    <p class="tx-center tx-24 tx-bold mb-5">
                        Este evento se agregará a tu lista de Próximos Eventos.
                    </p>
                    <p class="tx-center mb-9">
                        ¿Deseas continuar?
                    </p>
                    <div class="flex justify-center">
                        <button class="btn btn--danger mr-3" @click="handleConfirm('Cancelar agendar')">
                            Cancelar
                        </button>
                        <button class="btn btn--success" @click="handleConfirm('Aceptar agendar')">
                            Aceptar
                        </button>
                    </div>
                </div>
            </modal-alert>
        </transition>
    </div>
</template>
<style lang="scss" scoped>
    .box-title{
        background: linear-gradient(to right, #8708C6, #3C0778);
    }

    .box-body{
        background-color: rgba($color: #fff, $alpha: 0.3);
    }

    .decore-shine{
        max-width: initial;
    }

    .modal-close{
        top: initial;
        left: 0;
        bottom: 0;
    }
</style>