<script>
    import FormLogin from '@/components/auth/FormLogin.vue'
    import FormRegister from '@/components/auth/FormRegister.vue'
    import axios from 'axios';
    import ModalVideoPlayer from '@/components/ModalVideoPlayer.vue';

    export default {
        components: { FormRegister, FormLogin, ModalVideoPlayer },
        data: () => ({
            register: false,
            recover: false,
            modalVideo: false
        }),
        methods: {
            getPayPalSubDetail(){
                const Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}

                const clientID = 'AVoyk4hbRii1YgwcKo91Lkf6QlvKnggT9bOLS_9JQGjh9gO5rmZL2FzLhAsZlIDkFUcoXASqVq2U_5zy'
                const secret = 'EOOvci-5Z9jCAzl7kjnyb2a_3N3BJhVnlmNo4s1W4FRH3B9TzgKqTUd83w_4RdpFdla6OhDgoztnQHiW'

                const toB64 = Base64.encode(clientID+':'+secret);

                fetch('https://api-m.paypal.com/v1/billing/subscriptions/I-TBC00VJEKE32', {
                    headers: {
                        'Authorization': `Basic ${toB64}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })
                .then((response) => response.json())
                .then((responseData) => {  
                    this.$store.commit('setPaypalSubState', responseData.status)
                });
            },
            toggleVideo(){
                this.modalVideo = !this.modalVideo
                window.localStorage.setItem('zuggy-video-de-bienvenida', true)
            },
            toggleRegister(){
                this.register = !this.register
                if (window.localStorage.getItem('zuggy-video-de-bienvenida')) return
                this.toggleVideo()
            }   
        },
        mounted(){
            console.log(this.$route.params.register);
            if (this.$route.params.register) {
                this.register = true
            }
        }
    }
</script>
<template>
    <div class="login-view login-view-bg-a login-view-bg-a pt-10 pb-15" :class="register && 'login-view-bg-b'">
        <div class="container">
            <img class="block mx-auto w-40" src="@/assets/images/layout/logo.png" alt="">
            <div class="wrapper-box w-100pr p-4 bg-center bg-cover bg-no-repeat rounded-10 px-8 sm:px-12 py-13 sm:py-17 mx-auto" :style="{'background-image': `url(${require('@/assets/images/bg-modal-auth.png')})`}">
                <h1 class="tx-20 sm:tx-30 tx-center tx-strong mb-5">
                    <span v-if="!register">
                        {{ !recover ? 'INICIA SESIÓN' : 'Recuperar contraseña' }}
                    </span>
                    <span v-else>
                        REGÍSTRATE
                    </span>
                </h1>
                
                <div class="w-100pr max-w-100 mx-auto mb-4">
                    <form-login v-if="!register" @recover="recover = !recover"></form-login>
                    <form-register v-else></form-register>
                </div>

                <div class="tx-14 tx-medium tx-white tx-underline w-fit mx-auto cursor-pointer" @click="toggleRegister()">
                    <span v-if="!register">
                        SOY NUEVO USUARIO
                    </span>
                    <span v-else>
                        YA TENGO UNA CUENTA
                    </span>
                </div>
            </div>
        </div>

        <!-- <modal-video-player @close="toggleVideo()" v-if="modalVideo"></modal-video-player> -->
    </div>
</template>
<style lang="scss" scoped>
    .wrapper-box{
        background-color: rgba($color: #000000, $alpha: 0);
        background-size: 100% 100%;
        max-width: 677px;
    }
</style>
