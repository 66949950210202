<script>
    import axios from "axios"
    import ModalAlert from '@/components/ModalAlert.vue';

    export default {
    components: { ModalAlert },
        data: () => ({
            modal: false,
            render: false,
            swiperActivities: {
                allowTouchMove: true,
                slidesPerView: 1.2,
                centeredSlides: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                effect: 'coverflow',
                coverflowEffect: {
                    rotate: 1,
                    stretch: 235,
                    depth: 90,
                    modifier: 1,
                    slideShadows: true,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 1.8,
                    },
                },
            },
            swiperForums: {
                spaceBetween: 20,
                allowTouchMove: true,
                slidesPerView: 1.5,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                breakpoints: {
                    640: {
                        slidesPerView: 2.2,
                    },
                    1024: {
                        slidesPerView: 3.2,
                    },
                },
            },
            dataActivities: [
                { id: 1, title: '¡La Quiniela de la Liguilla Clausura 2023 ya está aquí!', description: 'Regístrate ahora y comparte con tus amigos. Compite contra la comunidad y participa por increíbles premios.', banner: 'test-1', url: '' },
                { id: 2, title: '¡La Velada del Año III se acerca! ¡Arma tu Quiniela!', description: 'Regístrate ahora y comparte con tus amigos. ¿Qué streamer es tu favorito para ganar?', banner: 'test-2', url: '' },
            ],
            dataForums: [
                { id: 1,  title: 'Rivers vs Rivers:', description: '¿Quién ganará?', url: '', banner: 'test-forum-1' },
                { id: 2,  title: 'GWMX 2023:', description: 'Dudas y soluciones', url: '', banner: 'test-forum-2' },
                { id: 3,  title: 'Fortnite:', description: 'Las mejores tacticas para ganar', url: '', banner: 'test-forum-3' },
                { id: 4,  title: 'Las mejores easter eggs', description: 'En call of duty', url: '', banner: 'test-forum-4' },
                { id: 5,  title: 'Preparate para el', description: 'campeonato de LOL', url: '', banner: 'test-forum-5' },
            ],
            dataNews: [
                { id: 1, title: '5 juegos fuera', description: 'de Xbox Game Pass a fin de mes', url: '', banner: 'test-news-1.png' },
                { id: 2, title: 'Metal Gear Solid 3', description: 'más cerca de lo que parece', url: '', banner: 'test-news-2.png' },
                { id: 3, title: 'Mortal Kombat 12', description: 'Lanzamiento', url: '', banner: 'test-news-3.png' },
            ],
            videosData: [
                { id: 1, banner: 'test-video-1.png', route: '' },
                { id: 2, banner: 'test-video-2.png', route: '' },
                { id: 3, banner: 'test-video-3.png', route: '' },
            ],
            myEvents: [],
            showTournaments: false,
            torneoSeleccionadoId: 0,
            restModal: true
        }),
        methods: {
            changeSlide(index) {
                this.$refs.swiperTop.$swiper.slideTo(index, 400);
            },
            nextSlide() {
                this.$refs.swiperTop.$swiper.slideNext();
            },
            prevSlide() {
                this.$refs.swiperTop.$swiper.slidePrev();
            },
            async getActivities (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `torneos-banners?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.dataActivities = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            async getTournaments (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `torneos?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.myEvents = res.data;
                } catch (err) { console.log(err) }
            },
            async getForums (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `foros?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.dataForums = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            async getNews (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `noticias?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.dataNews = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            goToActivities(url){
                this.trackLog({ 
                    action: 'Click actividades de la comunidad', 
                    medio: 'Banner', 
                    sectionId: 2, 
                    elementId: null, 
                })

                window.open(url, '_blank');
            },
            goToForum(path){
                this.trackLog({ 
                    action: 'Click foros de discusión', 
                    medio: 'Banner', 
                    sectionId: 2, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
            goToNews(path){
                this.trackLog({ 
                    action: 'Click noticias', 
                    medio: 'Banner', 
                    sectionId: 2, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
            goTournaments(path){
                this.trackLog({ 
                    action: 'Click en torneos', 
                    medio: 'Banner', 
                    sectionId: 2, 
                    elementId: null, 
                })
                this.$router.push({ path: path })
            },
            /*toggleTournament(){ 
                this.getTournaments();
                this.showTournaments = !this.showTournaments
            },*/
            beforeEnter(el){ el.style.height = 0 },
            enter(el){ el.style.height = el.scrollHeight + 'px' },
            beforeLeave(el){ el.style.height = el.scrollHeight + 'px' },
            leave(el){ el.style.height = 0 },
            async schedule(id){
                this.torneoSeleccionadoId = id
                this.trackLog({ 
                    action: 'Me interesa', 
                    medio: 'Botón', 
                    sectionId: 1, 
                    elementId: this.$route.params.id, 
                })

                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `registro-torneo?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=`+ this.$store.getters['userId'] +'&torneo_id=' + this.torneoSeleccionadoId);
                    console.log(res.data)
                   
                    if(res.data.error == 1){
                        this.restModal = false
                    } else {
                        this.restModal = true
                    }

                    this.modal = true
                    
                } catch (err) { console.log(err) }
            }
        },
        mounted(){
            this.getActivities();
            this.getForums();
            this.getNews();
            this.getTournaments();

            setTimeout(() => this.render = true, 0);

            this.trackLog({ 
                action: 'Ingreso a comunidad', 
                medio: 'Botón', 
                sectionId: 2, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="community-view relative pb-6">
        <div class="container pb-10">
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>ARENA ZUGGY CLUB GAMER</span>
            </div>

            <!-- SWIPER ACTIVITIES -->
            <swiper class="swiper swiper-activities mb-16" :options="swiperActivities" ref="swiperActivities" v-if="render">
                <swiper-slide v-for="(item, idx) in dataActivities" :key="idx">
                    <!--<a @click="goToActivities(item.url)" class="item-layer flex align-end w-100pr h-45 relative bg-center bg-cover bg-no-repeat rounded-10 border-1 p-4 z-0" :style="{ 'background-image': 'url(' + item.imagen + ')' }">-->
                    <div @click="goTournaments(`/torneo/${item.id}`)" class="item-layer flex align-center w-100pr relative bg-center bg-cover bg-no-repeat rounded-10 p-4 z-0 overflow-hidden">
                        <img class="rounded-20" :src="item.imagen" alt="">
                        <!-- <div class="max-w-75pr">
                            <h3 class="tx-14 mb-1 tx-medium max-h-10 overflow-hidden" v-html="item.titulo"></h3>
                            <p class="tx-12 mb-0 max-h-8 overflow-hidden" v-html="item.descripcion"></p>
                        </div> -->
                    </div>
                </swiper-slide>
            </swiper>

            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>FOROS DE DISCUSIÒN</span>
            </div>

            <!-- SWIPER FORUMS -->
            <swiper class="swiper swiper-forums mb-15" :options="swiperForums" ref="swiperActivities" v-if="render">
                <swiper-slide v-for="(item, idx) in dataForums" :key="idx">
                    <div class="item-layer item-forum relative z-0 flex align-end w-100pr h-70 relative bg-center bg-cover bg-no-repeat rounded-10 p-2 overflow-hidden" :style="{ 'background-image': 'url(' + item.imagen + ')' }" @click="goToForum(`/foro/${item.id}`)">
                        <div class="w-100pr">
                            <h3 class="tx-14 mb-1 tx-medium max-h-9 overflow-hidden" v-html="item.titulo"></h3>
                            <p class="tx-12 mb-0 h-8 overflow-hidden" v-html="item.sinapsis"></p>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>

            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>NOTICIAS</span>
            </div>
            <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-5">
                <div class="shadow-3" v-for="(item, idx) in dataNews.slice()" :key="idx" @click="goToNews(`/noticia/${item.id}/titulo`)">
                    <a class="item-layer z-0 h-52 flex align-end bg-t bg-cover bg-no-repeat rounded-10" :style="{ 'background-image': 'url(' + item.imagen + ')' }">
                        <div class="container-noticias tx-center grid align-center justify-center h-18 rounded-b-10 p-2">
                            <h3 class="tx-14 mb-1 tx-medium overflow-hidden" v-html="item.titulo"></h3>
                            <!--<p class="tx-12 mb-0 overflow-hidden" v-html="item.descripcion"></p>-->
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <div class="relative">

            <!-- LIST EVENTS -->
            <!--<transition
                    @before-enter="beforeEnter"
                    @enter="enter"
                    @before-leave="beforeLeave"
                    @leave="leave"
                >
                    <div :style="{ 'margin': '5%' }" class="mb-5 overflow-hidden transition-400" v-if="showTournaments">
                        <div class="mb-6">
                            <ul class="tx-14 bullet-none pl-0 mt-0">
                                <li class="flex justify-between align-center py-2 border-b-1 border-white px-4" v-for="(item, idx) in myEvents" :key="idx">
                                    <span class="">
                                        {{ item.titulo }}
                                    </span>
                                    <span class="">
                                        <div class="flex align-center pl-4">
                                            <div class="w-10 grid place-items-center ml-2">
                                                <div class="tx-center w-fit mx-auto relative" @click="schedule(item.id)">
                                                    <div class="relative w-fit mx-auto flex flex-column align-center justify-center mb-1">
                                                        <img class="w-12" src="@/assets/images/layout/icon-plus.png" alt="">
                                                        <img class="decore-shine mx-auto b-0 -mt-2" src="@/assets/images/views/zuggy_ground/shine-btn-plus.png" alt="">
                                                    </div>
                                                    <span class="tx-14 tx-medium">
                                                        Me interesa
                                                    </span>
                                                </div> 
                                            </div>
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </transition>-->
            <!-- LAYER RESTRICT 
            <layer-restrict class="w-fit" :type="2" v-if="!$store.getters['profileCompleted']"/>-->
        </div>

        <!-- MODAL -->
        <transition name="fade">
            <modal-alert v-if="modal" @close="modal = false">
                <div class="py-4 px-1" v-if="restModal">
                    <p class="tx-center tx-24 tx-bold mb-5">
                        Gracias por registrarte.
                    </p>
                    <div class="flex justify-center">
                        <button class="btn btn--success" @click="modal = false">
                            Aceptar
                        </button>
                    </div>
                </div>
                <div class="py-4 px-1" v-else>
                    <p class="tx-center tx-24 tx-bold mb-5">
                        Ya estas registrado.
                    </p>
                    <div class="flex justify-center">
                        <button class="btn btn--success" @click="modal = false">
                            Aceptar
                        </button>
                    </div>
                </div>
            </modal-alert>
        </transition>
    </div>    
</template>
<style lang="scss" scoped>
    .container-noticias{
        max-height: 8rem;
        position: relative;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        background-color: #3C0778;
    }

    .justify-start{
        align-content: flex-start;
    }

    .item-forum::after{
        background-image: linear-gradient(rgba(#000, 0), rgba(#000, 0.2), rgba(#000, 1));
        border-radius: 0;
    }
</style>