<script>
    export default {
        props: {
            data: Object
        },
        methods: {
            handleClick(path){
                this.trackLog({ 
                    action: 'Click en producto', 
                    medio: 'Botón', 
                    sectionId: 3, 
                    elementId: null, 
                })

                this.$router.push({ path: path });
            }
        }
    }
</script>
<template>
    <div class="product-item block relative cursor-pointer" @click="handleClick(`/producto/${data.id}`)">
        <div class="flex align-end relative z-1">
            <div class="bg-center bg-cover bg-no-repeat h-30 w-30 xl:h-45  xl:w-45 flex-none rounded-16 mr-5" :style="{ 'background-image': 'url(' + data.imagen + ')' }"></div>
            <div class="w-100pr pb-4 xl:pb-8">
                <p class="tx-14 tx-strong mb-3">
                    {{ data.titulo }}
                </p>
                <p class="tx-12 tx-uppercase w-100pr mb-0 tx-10 mb-4" v-html="data.promocion"></p>
                <div class="flex align-center">
                    <div class="relative btn h-7 tx-bold rounded-10 mr-2 xl:mr-8 px-1 shadow-2">
                        <img class="mr-1" src="@/assets/images/views/store/icon-cart.png" alt="">
                        Comprar
                    </div>
                    <span class="tx-16 h-6 tx-bold bg-white tx-purple rounded-16 px-2 pt">
                        ${{ data.monto }}
                    </span>
                </div>
            </div>
        </div>
        
        <!-- DECORE -->
        <img class="absolute t-0 l-0 b-0 m-auto w-100pr" src="@/assets/images/views/store/bg-product.png" alt="">
    </div>
</template>
<style lang="scss" scoped>
    .btn{
        color: #fff;
        background-color: #B51FBC;
        border: none;
        padding: 1px 8px!important;
    }
</style>

