<script>
    import axios from "axios"
    import ModalAlert from '@/components/ModalAlert.vue';

    export default {
    components: { ModalAlert },
        data: () => ({

        }),
        methods: {
            goToTournament(id){
                this.$router.push({ path: `/torneo/${id}` });
            }
        },
        mounted(){
            this.trackLog({ 
                action: 'Ingreso a torneos', 
                medio: 'Botón', 
                sectionId: 10, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="community-view relative pb-6">
        <div class="container pb-10">
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>Torneos</span>
            </div>

            <img class="mb-10" src="@/assets/images/views/tournaments-banner.png" alt="">

            <!-- ITEM -->
            <div class="product-item block relative cursor-pointer py-5 sm:py-0" @click="goToTournament(1)">
                <div class="flex align-center sm:align-end relative z-1">

                    <div class="bg-center bg-cover bg-no-repeat h-30 sm:h-60 w-30 sm:w-60 xl:w-100 flex-none rounded-16 mr-5" :style="{ 'background-image': 'url(' + require('@/assets/images/views/test-banner-tournament.png') + ')' }"></div>

                    <div class="tx-12 sm:tx-16 relative w-100pr pb-4 xl:pb-8 pr-10 md:pr-15">
                        <!-- BADGE -->
                        <img class="badge hidden md:block absolute r-0 t-0 mr-6 lg:mr-13 -mt-2" src="@/assets/images/views/test-badge-tournament.png" alt="">

                        <!-- DATA -->
                        <p class="tx-strong mb-2">
                            Torneo Call of Duty
                        </p>
                        <p class="tx-medium mb-0">
                            FECHA: <span class="tx-purple-3">23 Enero 2025</span>
                        </p>
                        <p class="tx-medium mb-0 sm:mb-4">
                            PREMIO: <span class="tx-purple-3">$2500</span>
                        </p>
                        <p class="tx-12 tx-medium w-100pr mb-0 tx-10 mb-1 sm:mb-4">
                            PATROCINADO POR: SONY 
                        </p>
                        <div class="tx-14 md:tx-18 tx-center md:ml-auto md:h-7 w-15 md:w-20 tx-bold bg-white tx-purple rounded-16 px-2 sm:pt-1 mb-3">
                            $700
                        </div>
                        <div class="tx-8 sm:tx-10">
                            Para recibir la información es necesario que registres tu correo electrónico en tu perfil.
                        </div>
                    </div>
                </div>
                
                <!-- DECORE -->
                <img class="decore absolute t-0 r-0 b-0 m-auto h-100pr" src="@/assets/images/views/store/bg-product.png" alt="">
            </div>
        </div>
    </div>    
</template>
<style lang="scss" scoped>
    .decore{
    }
</style>