
<script>
    export default {
        data: () => ({
            sm: [
                /* { url: '', label: 'discord' }, */
                { url: 'https://www.facebook.com/ZuggyClubGamers/', label: 'facebook' },
                { url: 'https://www.instagram.com/zuggyclubgamer/', label: 'instagram' },
                { url: 'https://x.com/zuggyclubgamer', label: 'x' },
                { url: 'https://www.tiktok.com/@zuggyclubgamer', label: 'tiktok' },
            ],
        }),
        methods: {
            logOut() {
                localStorage.removeItem('vuex');
                localStorage.removeItem('user-paypal-id');
                window.location.reload();
            }
        }
    }
</script>
<template>
    <footer class="main-footer p-4">
        <div class="container">
            <p class="tx-center tx-16 md:tx-20 mb-2">
                Síguenos en
            </p>
            <div class="grid grid-cols-4 gap-4 w-fit mx-auto mb-2">
                <a :href="item.url" target="_blank" v-for="(item, idx) in sm">
                    <img class="mx-auto w-8 h-8" :src="require(`@/assets/images/icon-sm/${item.label}.svg`)" alt="">
                </a>
            </div>
            <router-link class="tx-14 tx-bold tx-purple-3 tx-center flex w-fit tx-underline mx-auto" to="/aviso" exact>
                Aviso de privacidad
            </router-link>
            <div class="w-fit tx-danger tx-underline mx-auto mt-1 cursor-pointer" @click="logOut()" v-if="$store.getters['logeddIn']">
                Cerrar sesión
            </div>
        </div>
    </footer>
</template>
<style lang="scss" scoped>
    .main-footer{
        background-color: #522382;
    }
</style>