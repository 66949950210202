<script>
    export default {
        props: {
            type: Number
        },
        methods: {
            handleRestrict(){
                if (this.type === 2) {
                    this.$store.state.restrictModal.type = this.type;
                    this.$store.state.restrictModal.text = 'Para poder completar esta acción <br> primero tendrás que tener un nombre de usuario y terminar tu perfilamiento. <br> <div class="tx-glow tx-medium mt-2">Ve a “Perfil” y ayúdanos con esta información.</div>';
                    this.$store.state.restrictModal.btnLabel = 'Ir a Perfil';
                }
                if (!this.$store.getters['logeddIn']) {
                    this.$router.push({ path: '/login' });
                    return
                }
                this.$store.commit('toggleRestrictModal')
            },
        }
    }
</script>

<template>
    <div class="absolute t-0 l-0 h-100pr w-100pr z-1" @click="handleRestrict()"></div>
</template>