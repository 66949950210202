<script>
    import axios from 'axios';
    
    import ModalVideoPlayer from '@/components/ModalVideoPlayer.vue';
    import MyButton from '@/components/ui/MyButton.vue';
    import * as sdkclaro from "@claro/sdkclaro";
    import HeaderComponent from '@/components/layout/HeaderComponent.vue';
    import FooterComponent from '@/components/layout/FooterComponent.vue';
    
    export default {
        components: { ModalVideoPlayer, MyButton, HeaderComponent, FooterComponent },

        data: () => ({
            modal: true,
            showVideo: '',
            swiper: {
                allowTouchMove: true,
                slidesPerView: 1.2,
                centeredSlides: true,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                effect: 'coverflow',
                coverflowEffect: {
                    rotate: 1,
                    stretch: 235,
                    depth: 90,
                    modifier: 1,
                    slideShadows: true,
                },
                breakpoints: {
/*                     640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    }, */
                    1024: {
                        slidesPerView: 1.8,
                    },
                },
            },
            dataEvents: [],
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
            showCalendar: false,
            dataEvents: [],
            nextEvent: null,
            nextEventDate: null,
            myEvents: {},
            featuredVideos:[],
        }),
        methods: {
            async validateProfileClaroPay(){
                console.log('validateProfileClaroPay welcome')

                if(this.$store.getters['isMember']){
                    this.$router.replace({ path: '/zona-premier' })
                }
            },
            changeSlide(index) {
                this.$refs.swiper.$swiper.slideTo(index, 400);
            },
            nextSlide() {
                this.$refs.swiper.$swiper.slideNext();
            },
            prevSlide() {
                this.$refs.swiper.$swiper.slidePrev();
            },
            closeModal() {
                this.modal = false;
                this.trackLog({ 
                    action: 'Video visto', 
                    medio: 'Botón', 
                    sectionId: 1, 
                    elementId: null, 
                })
            },
            async getEvents (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `proximos-eventos?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.dataEvents = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            async getMyEvents (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `mis-eventos?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=` + this.$store.getters['userId']);

                    if (res.data.length <= 0) {
                        this.nextEvent = null
                        this.nextEventDate = null
                        return
                    }

                    const sortByDate = res.data.sort((a,b) => new Date(a.fecha) - new Date(b.fecha));

                    /* ORDER EVENTS BY MONTH */
                    this.myEvents = res.data.reduce((value, obj) => {
                        let date = new Date(obj.fecha).getMonth();
                    
                        value[date] = (value[date] || []).concat(obj);
                        return value;
                    }, {});
                    
                    /* NEXT EVENT */
                    this.nextEvent = sortByDate[0];
                    this.nextEventDate = new Date(sortByDate[0].fecha);

                } catch (err) { console.log(err) }
            },
            async getFeaturedVideos (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `videos-destacados?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}`);
                    this.featuredVideos = res.data.filter(i => i.activo === 1);
                } catch (err) { console.log(err) }
            },
            async deleteEvent(eventId){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `baja-evento?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=${this.$store.getters['userId']}&evento_id=${eventId}`);
                    this.getMyEvents()
                    this.toggleCalendar()
                } catch (err) { console.log(err) }
            },
            async getShowVideo (){
                try{
                    const res = await axios.post(process.env.VUE_APP_SERVICE_URL  + `welcome-video?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&usuario_id=` + localStorage.getItem('user-paypal-id'));
                    this.current = res.data

                    if(this.current.video == 1){
                        this.showVideo = true
                    } else {
                        this.showVideo = false
                    }

                } catch (err) { console.log(err) }
            },
            goToEvent(path){
                this.trackLog({ 
                    action: 'Click próximos eventos', 
                    medio: 'Banner', 
                    sectionId: 1, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
            goToVideo(path){
                this.trackLog({ 
                    action: 'Click Video', 
                    medio: 'Banner', 
                    sectionId: 2, 
                    elementId: null, 
                })
                this.$router.push({ path: path })
            },
            goToNextEvent(path){
                this.trackLog({ 
                    action: 'Click mi siguiente evento', 
                    medio: 'Banner', 
                    sectionId: 1, 
                    elementId: null, 
                })

                this.$router.push({ path: path })
            },
            goToFeaturedVideo(url){
                this.trackLog({ 
                    action: 'Click videos destacados', 
                    medio: 'Banner', 
                    sectionId: 1, 
                    elementId: null, 
                })

                window.open(url, '_blank');
            },
            toggleCalendar(){ this.showCalendar = !this.showCalendar },
            beforeEnter(el){ el.style.height = 0 },
            enter(el){ el.style.height = el.scrollHeight + 'px' },
            beforeLeave(el){ el.style.height = el.scrollHeight + 'px' },
            leave(el){ el.style.height = 0 },
        },
        mounted(){
            sdkclaro.getInstance().switchGoBackButton(true);
            this.validateProfileClaroPay();
            this.getShowVideo();

            this.getEvents();
            this.getMyEvents();
            this.getFeaturedVideos();

            setTimeout(() => this.render = true, 0);

            this.trackLog({ 
                action: 'Ingreso a arena zuggy', 
                medio: 'Botón', 
                sectionId: 1, 
                elementId: null, 
            })
        }
    }
</script>
<template>
    <div class="zuggy-ground-view">
        <div class="container">
            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>PRÓXIMOS EVENTOS</span>
            </div>

            <!-- SWIPER -->
            <swiper class="swiper mb-10" :options="swiper" ref="swiperTop">
                <swiper-slide v-for="(item, idx) in dataEvents" :key="idx">
                    <div class="item-event flex align-center w-100pr relative bg-center bg-cover bg-no-repeat rounded-10 p-4 z-0 overflow-hidden" @click="goToEvent(`/evento/${item.id}`)">
                        <img class="rounded-20" :src="item.imagen" alt="">
                        <!-- <div class="absolute max-w-75pr">
                            <h3 class="tx-14 mb-1 tx-medium max-h-9 overflow-hidden">
                                {{ item.titulo }}
                            </h3>
                            <p class="tx-12 mb-0 max-h-8 overflow-hidden">
                                {{ item.sinapsis }}
                            </p>
                        </div> -->
                    </div>
                </swiper-slide>
            </swiper>

            <!-- CALENDAR -->
            <template v-if="nextEvent === null">
                <div class="decore-title-md w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                    <span>CALENDARIO INTERACTIVO</span>
                </div>
                <div class="flex mb-10 justify-center">
                    <img src="@/assets/images/views/home/schedule.svg" alt="">
                </div>
            </template>
            <div class="mb-6" v-else>
                <div class="decore-title-md w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-3">
                    <span>TU PRÓXIMO EVENTO:</span>
                </div>
                <p class="tx-14 tx-bold tx-italic tx-center mb-8">
                    {{ nextEvent.titulo }}
                </p>
                
                <!-- NEXT EVENT -->
                <div class="mb-5" @click="toggleCalendar()">
                    <div class="next-event relative flex align-end bg-center bg-cover bg-no-repeat border-white h-41 sm:h-60 w-100pr rounded-10 z-0 overflow-hidden p-3" :style="{ 'background-image': 'url(' + nextEvent.imagen + ')' }">
                        <span>
                            {{ nextEventDate.getMonth() + 1 }} - 
                            {{ nextEventDate.getDate() }} - 
                            {{ nextEventDate.getHours() }}
                            :
                            {{ (nextEventDate.getMinutes() < 10 ? '0' : '') + nextEventDate.getMinutes() }}
                        </span>
                    </div>
                </div>

                <!-- LIST EVENTS -->
                <transition
                    @before-enter="beforeEnter"
                    @enter="enter"
                    @before-leave="beforeLeave"
                    @leave="leave"
                >
                    <div class="mb-5 overflow-hidden transition-400" v-if="showCalendar">
                        <div class="mb-6" v-for="(val, month) in myEvents" :key="month">
                            <p class="tx-medium mb-1">
                                {{ months[month] }}
                            </p>
                            <ul class="tx-14 bullet-none pl-0 mt-0">
                                <li class="flex justify-between align-center py-2 border-b-1 border-white px-4" v-for="(event, idx) in val" :key="idx">
                                    <span class="tx-underline" @click="goToNextEvent(`/evento/${event.id}`)">
                                        {{ event.titulo }}
                                    </span>
                                    <div class="flex align-center pl-4">
                                        <span>
                                            {{ new Date(event.fecha).getDate() }}
                                        </span>
                                        <div class="h-10 w-10 grid place-items-center ml-2" @click="deleteEvent(event.evento_id)">
                                            <img class="w-5" src="@/assets/images/layout/icon-trash.png" alt="">
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </transition>
            </div>  

            <!-- VIDEOS -->
            <div class="decore-title-md w-100pr tx-14 h-10 tx-bold grid align-center tx-center tx-italic mb-8">
                <span>VIDEOS DESTACADOS</span>
            </div>
            
            <div class="grid grid-cols-2 md:grid-cols-3 gap-5 mb-10">
                <a class="h-25 md:h-35 relative bg-center bg-cover bg-no-repeat rounded-10 shadow-5 relative overflow-hidden" :style="{ 'background-image': 'url(' + video.banner + ')' }" v-for="(video, idx) in featuredVideos.slice().reverse()" :key="idx" @click="goToVideo(`/video/${video.id}`)">
                    <div class="video-layer absolute t-0 l-0 w-100pr h-100pr grid justify-center align-center transition-100">
                        <img class="w-10 md:w-13 transition-200" src="@/assets/images/layout/button-video.svg" alt="">
                    </div>
                </a>
            </div>
        </div>

        <!-- MODAL VIDEO -->
        <!-- <transition name="fade" v-if="showVideo">
            <modal-video-player class="z-10" @close="closeModal()" v-if="modal"/>
        </transition> -->
    </div>
</template>

<style lang="scss" scoped>
    .d-none {
        display: none;
    }

    .video-layer{
        background-color: rgba($color: #4C1077, $alpha: 0.6);
    }

    .video-layer:hover{
        background-color: rgba($color: #000000, $alpha: 0);
        img{ opacity: 0; }
    } 
    
    .swiper-slide{
        opacity: 0!important;
    }

    .swiper-slide-next,
    .swiper-slide-prev{
        opacity: 0.8!important;
    }

    .swiper-slide-active{
        opacity: 1!important;
    }
</style>