<script>
    import FormRegister from './FormRegister.vue'
    import FormLogin from './FormLogin.vue'

    export default {
        components: { FormRegister, FormLogin },
        data: () => ({
            register: false,
            recover: true
        }),
    }
</script>

<template>
    <div class="modal flex align-center z-10">
        <div class="modal-lightbox w-100pr p-4 bg-center bg-cover bg-no-repeat rounded-10 px-8 sm:px-12 py-13 sm:py-17" :style="{'background-image': `url(${require('@/assets/images/bg-modal-auth.png')})`}">
            <div class="modal-close -mt-6 -mr-6" @click="$emit('close')">
                <img src="@/assets/images/layout/icon-close.png" alt="">
            </div>

            <h1 class="tx-20 sm:tx-30 tx-center tx-strong mb-5 tx-uppercase">
                <span v-if="!register">
                    {{ !recover ? 'INICIA SESIÓN' : 'Recuperar contraseña' }}
                </span>
                <span v-else>
                    REGÍSTRATE
                </span>
            </h1>
            
            <div class="w-100pr max-w-100 mx-auto mb-4">
                <form-login v-if="!register" @recover="recover = !recover"/>
                <form-register v-else/>
            </div>

            <div class="tx-14 tx-medium tx-white tx-underline w-fit mx-auto cursor-pointer" @click="register = !register">
                <span v-if="!register">
                    SOY NUEVO USUARIO
                </span>
                <span v-else>
                    YA TENGO UNA CUENTA
                </span>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .modal{
        width: 100%;
        background: rgba(0, 0, 0, 0.18);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(13.7px);
        -webkit-backdrop-filter: blur(13.7px);

        &-lightbox{
            background-color: rgba($color: #000000, $alpha: 0);
            background-size: 100% 100%;
            max-width: 677px;
        }
    }
</style>