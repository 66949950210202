<script>
    export default {
        methods: {
            handleClick(){
                if (!this.$store.getters['logeddIn']) {
                    this.$router.push({ path: '/login' });
                    return
                }

                this.$router.push({ path: '/comprar-membresia' }); 
            }
        }
    }
</script>
<template>
    <div class="fixed b-0 w-100pr h-0 z-1">
        <div class="container relative pr-4">
            <div class="btn-membership flex flex-column align-center justify-center tx-center rounded-100pr w-24 h-24 cursor-pointer ml-auto mb-5 absolute b-0 r-0 mr-5" @click="handleClick()">
                <img class="icon w-10 mb-1" src="@/assets/images/credit-card.png" alt="">
                <p class="tx-12">
                    Obtener <br> membresia
                </p>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .btn-membership{
        background-color: #522382;
        box-shadow: 0px 4px 12px rgba($color: #000000, $alpha: 0.5);
    }

    .icon{
        filter: invert(100%) sepia(0%) saturate(7499%) hue-rotate(97deg) brightness(103%) contrast(101%);
    }
</style>

