<script>
    import axios from 'axios'

    import NavBarMenu from '@/components/layout/NavBarMenu.vue'
    import ProductItem from '@/components/ProductItem.vue'
    import HeaderComponent from '@/components/layout/HeaderComponent.vue'
    import * as sdkclaro from "@claro/sdkclaro";

    export default {
        components: { NavBarMenu, ProductItem, HeaderComponent },
        data: () => ({
            headerHeight: 0,
            loaded: false,
            loading: true,
            aBuscar: '',
            inputToSearch: '',
            dataProducts: [
                { id: 1, title: 'xbox 6 meses', description: 'Compra 6 meses de Xbox Ultimate precios especial.', price: 699, promotion: 'Con tu compra ganas XXXX puntos', category: 'plataformas', platform: 'xbox', date: '20 de junio' },
                { id: 2, title: 'playstation 2 mese', description: 'Adquiere 2 meses de PlayStation a un precio especial.', price: 450, promotion: 'Con tu compra ganas XXXX puntos', category: 'plataformas', platform: 'playstation', date: '20 de junio' },
                { id: 3, title: 'nintendo 3 meses', description: 'Compra 3 meses de Nintendo Online a un súper precio.', price: 599, promotion: 'Con tu compra ganas XXXX puntos', category: 'plataformas', platform: 'nintendo', date: '20 de junio' },
                { id: 4, title: 'play store 2 meses', description: 'Compra tu membresía de 2 meses en Play Store', price: 250, promotion: 'Con tu compra ganas XXXX puntos', category: 'plataformas', platform: 'playStore', date: '20 de junio' },
            ],
            founds: null
        }), 
        computed: {
            getCategoryName(){
                return this.$route.params.name
            },
        },
        methods: {
            load (){
                this.loading = false
            },
            async getProducts (){
                try{
                    const checkSubcategory = this.$route.params.subcategoryId == 0 ? '&sub_categoria_id=' : '&subcategoria_id='

                    const res = await axios.post(
                        process.env.VUE_APP_SERVICE_URL + `productos-tienda?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&id=` + this.$route.params.categoryId + checkSubcategory + this.$route.params.subcategoryId
                    );
                    
                    this.dataProducts = res.data
                    this.load();
                } catch (err) { console.log(err) }
            },
            async filter(){
                
                this.loading = true

                const a = '&buscar=' + this.aBuscar;

                const checkSubcategory = this.$route.params.subcategoryId == 0 ? '&sub_categoria_id=' : '&subcategoria_id='

                const res = await axios.post(
                    process.env.VUE_APP_SERVICE_URL + `productos-tienda?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&id=` + this.$route.params.categoryId + checkSubcategory + this.$route.params.subcategoryId + a
                );

                console.log(res.data)
                    
                this.dataProducts = res.data
                this.load();
            },
            async clearFilter(){
                
                this.loading = true
                this.aBuscar = '';
                const a = '&buscar=' + this.aBuscar;

                const checkSubcategory = this.$route.params.subcategoryId == 0 ? '&sub_categoria_id=' : '&subcategoria_id='

                const res = await axios.post(
                    process.env.VUE_APP_SERVICE_URL + `productos-tienda?plataforma_id=${process.env.VUE_APP_PLATFORM_ID}&id=` + this.$route.params.categoryId + checkSubcategory + this.$route.params.subcategoryId + a
                );
                    
                this.dataProducts = res.data
                this.load();
            },
            toSearch() {
                if (this.inputToSearch == '') return [];
                this.getProducts().then( 
                    this.founds = this.dataProducts.filter(i => i.titulo.toLowerCase().includes(this.inputToSearch.toLowerCase()))
                )
            },
            showAll(){
                this.founds = null
            },
        },
        mounted() {
            this.getProducts();
            sdkclaro.getInstance().switchGoBackButton(true)
            
            const header = document.querySelector('.main-header');
            this.headerHeight = (header.offsetHeight + 24) + 'px';

            this.trackLog({ 
                action: 'Ingreso a categoría', 
                medio: 'Botón', 
                sectionId: 3, 
                elementId: this.$route.params.categoryId, 
            })
        }
    }
</script>
<template>
    <div class="store-category-view">
        <div class="container mb-16">

            <div class="decore-title-lg w-100pr tx-14 h-10 tx-bold tx-uppercase  grid align-center tx-center tx-italic mb-15">
                <span>{{ getCategoryName }}</span>
            </div>

            <button class="btn btn--purple flex mb-4 ml-auto" @click="showAll()">
                <img class="mr-2" src="@/assets/images/views/store/see-all.png" alt="">
                Ver todos
            </button>

            <!-- <div class="form-group mb-3">
                <div class="flex">
                    <img class="-mr-4 z-1 -mt-1" src="@/assets/images/layout/icon-search.png" alt="">
                    <input class="form-input rounded-r-10 pl-10" type="text" placeholder="Ingresa el nombre a buscar" maxlength="50" v-model="aBuscar">
                </div>
            </div>

            <button class="btn btn--purple btn--search flex mx-auto tx-bold mt-3 mb-7" @click="filter()">BUSCAR</button> -->
            
            <div class="form-group mb-7">
                <div class="flex">
                    <img class="-mr-4 z-1 -mt-1" src="@/assets/images/layout/icon-search.png" alt="">
                    <input class="form-input rounded-r-10 pl-10" type="text" placeholder="Ingresa el nombre a buscar" maxlength="50" v-model="inputToSearch">
                </div>
            </div>

            <button class="btn btn--purple btn--search flex mx-auto tx-bold mt-3 mb-7" @click="toSearch()">BUSCAR</button>

            <div class="grid sm:grid-cols-2 gap-6" v-if="founds != null">
                <product-item class="mb-3" v-for="(item, idx) in founds" :key="idx" :data="item"/>
            </div>
            <div class="grid sm:grid-cols-2 gap-6" v-else>
                <product-item class="mb-3" v-for="(item, idx) in dataProducts" :key="idx" :data="item"/>
            </div>
        </div>

        <!-- LOADER -->
        <div class="overlay fixed grid place-items-center t-0 l-0 w-100pr h-100pr z-10" v-if="loading">
            <div class="loader-spinner"></div>
        </div>

        <!-- <layer-restrict v-if="!$store.getters['isMember']"/> -->
    </div>
</template>
<style lang="scss" scoped>
    .form-input{
        background-color: rgba($color: #fff, $alpha: 0.5);
        border: 0;

        &::placeholder{
            color: #fff;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .text-right{
        margin-top: 10px;
        text-align: right;
    }

    .overlay{
        background: rgba($color: #000, $alpha: 0.4)
    }

    .btn--search{
        border-radius: 15px;
    }
</style> 