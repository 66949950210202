import Vue from "vue";
import VueRouter from "vue-router";

import LoginView from '@/views/LoginView.vue';

import HomeView from '@/views/HomeView.vue';

import WelcomeViewVue from "@/views/WelcomeView.vue";

import IntroductionView from "@/views/register/PurchaseMembershipView.vue";
import PayMethodView from "@/views/register/PayMethodView.vue";
import RegisteredView from "@/views/register/RegisteredView.vue";

import CreateProfileView from "@/views/user/CreateProfileView.vue";
import ProfileView from "@/views/user/ProfileView.vue";
import EditProfileView from "@/views/user/EditProfileView.vue";

import ShoppingHistoryView from "@/views/user/ShoppingHistoryView.vue";

import AppLayout from "@/views/home/AppLayout.vue";

import ZuggyGroundView from "@/views/home/ZuggyGroundView.vue";
import EventDetailView from "@/views/home/EventDetailView.vue";
import ForumDetailView from "@/views/home/ForumDetailView.vue"

import CommunityView from "@/views/home/CommunityView.vue";

import TournamentsView from "@/views/home/TournamentsView.vue";
import NewDetailTournamentsView from "@/views/home/NewDetailTournamentsView.vue";

import NewDetailView from "@/views/home/NewDetailView.vue";
import ExperienceDetailView from "@/views/home/ExperienceDetailView.vue";
import VideoDetailView from "@/views/home/VideoDetailView.vue";

import StoreView from "@/views/home/StoreView.vue";

import CategoryView from "@/views/store/CategoryView.vue";
import SubcategoryView from "@/views/store/SubcategoryView.vue";
import ProductDetailView from "@/views/store/ProductDetailView.vue";

import ScratchAndWinView from "@/views/ScratchAndWinView.vue";
import PoolsView from "@/views/PoolsView.vue";

import RewardsView from "@/views/RewardsView.vue";
import RewardDetailView from "@/views/RewardDetailView.vue"
import RewardDetailVivePlus from "@/views/RewardDetailVivePlus.vue"

import ChatView from "@/views/ChatView.vue";

import HelpView from "@/views/HelpView.vue";
import PreguntasFrecuentesView from "@/views/PreguntasFrecuentesView.vue";

import SupportView from "@/views/home/SupportView.vue";

import TermsView from "@/views/home/TermsView.vue";
import TermsViewInit from "@/views/home/TermsViewInit.vue";
import AvisoView from "@/views/home/AvisoView.vue";
import PoliticasView from "@/views/home/PoliticasView.vue";
import ForosView from "@/views/home/ForosView.vue";

import NotFoundView from "@/views/NotFoundView.vue";
import store from "@/store/old_index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login/:register?",
    name: "Login",
    component: LoginView,
    meta: { 
      logged: true,
    }
  },
  {
    path: "/",
    redirect: "zona-premier"
     //meta: { requireAuth: true }
  },
  {
    path: "/bienvenida",
    name: "bienvenida",
    component: WelcomeViewVue,
     //meta: { requireAuth: true }
  },
  {
    path: "/comprar-membresia",
    name: "Comprar membresia",
    component: IntroductionView,
     meta: { 
      requireAuth: true,
      noRequireMembership: true
    }
  },
/*   {
    path: "/registro/metodo-de-pago",
    name: "metodo-de-pago",
    component: PayMethodView,
  }, */
/*   {
    path: "/registro/completado",
    name: "completado",
    component: RegisteredView,
     meta: { requireAuth: true }
  }, */
  {
    path: "/inicio",
    name: "inicio",
    component: AppLayout,
    meta: { requireAuth: true },
    children: [
      { name: 'Zona Premier' , path: '/zona-premier', component: HomeView,  meta: { requireAuth: true } },
      { name: 'Detalle del evento' , path: '/evento/:id', component: EventDetailView,  meta: { requireAuth: true } },
      { name: 'Detalle del Foro' , path: '/foro/:id', component: ForumDetailView,  meta: { requireAuth: true } },
      { name: 'Comunidad' , path: '/comunidad', component: CommunityView,  meta: { requireAuth: true } },
      { name: 'Detalle de noticia', path: '/noticia/:id/:name', component: NewDetailView,  meta: { requireAuth: true } },

      { name: 'Torneos', path: '/torneos', component: TournamentsView,  meta: { requireAuth: true }},
      { name: 'Torneo', path: '/torneo/:id', component: NewDetailTournamentsView,  meta: { requireAuth: true }},
      
      { name: 'Experiencia', path: '/experiencia/:id/:name', component: ExperienceDetailView,  meta: { requireAuth: true }},
      { name: 'Tienda' , path: '/tienda', component: StoreView, meta: { requireAuth: true } },
      { 
        name: "Subcategoria de tienda",  
        path: "/subcategorias/:name/:id",
        component: SubcategoryView,
        meta: { requireAuth: true }
      },
      {
        name: "Categoria de tienda",  
        path: "/categoria/:categoryId/:name/:subcategoryId",
        component: CategoryView,
        meta: { requireAuth: true }
      },
      {
        name: "Detalle de producto",  
        path: "/producto/:id",
        component: ProductDetailView,
        meta: { requireAuth: true }
      },
      {
        name: "Recompensas",
        path: "/recompensas",
        component: RewardsView,
        meta: { requireAuth: true }
      },
      {
        path: "/recompensa/:id",
        name: "Recompensa",
        component: RewardDetailView,
        meta: { requireAuth: true }
      },
      {
        name: "Asistencia",
        path: "/asistencia",
        component: HelpView,
        meta: { requireAuth: true }
      },
      { name: 'Términos y condiciones' , path: '/terminos', component: TermsView, meta: { requireAuth: true } },
      { name: 'Aviso de privacidad' , path: '/aviso', component: AvisoView, meta: { requireAuth: true } },
      { name: 'Política de reembolso de códigos digitales' , path: '/politicas', component: PoliticasView, meta: { requireAuth: true } },
      { name: 'Políticas de foros' , path: '/foros', component: ForosView, meta: { requireAuth: true } },

      { name: 'Video destacado', path: '/video/:id', component: VideoDetailView, meta: { requireAuth: true } },

      {
        name: "Preguntas frecuentes",
        path: "/preguntas-frecuentes",
        component: PreguntasFrecuentesView,
        meta: { requireAuth: true }
      },
      {
        name: "Soporte Tecnico",
        path: "/soporte-tecnico",
        component: SupportView,
        meta: { requireAuth: true }
      },
      {
        path: "/mi-perfil",
        name: "Mi perfil",
        component: ProfileView,
        meta: { requireAuth: true }
      },
      {
        path: "/crear-perfil",
        name: "Crear perfil",
        component: CreateProfileView,
        meta: { requireAuth: true }
      },
      {
        path: "/editar-perfil",
        name: "Editar perfil",
        component: EditProfileView,
        meta: { requireAuth: true }
      },
      {
        path: "/historial-de-compras",
        name: "Historial de compras",
        component: ShoppingHistoryView,
        meta: { requireAuth: true }
      },
      {
        path: "/viveplus",
        name: "Vive Plus",
        component: RewardDetailVivePlus,
        meta: { requireAuth: true }
      },
    ]
  },
  {
    path: "/terminos-init",
    name: "Términos y condiciones",
    component: TermsViewInit,
/*         meta: { requireAuth: true } */
  },
  { name: 'Aviso de privacidad' , path: '/aviso-init', component: AvisoView },
/*
  {
    path: "/rasca-y-gana",
    name: "rasca-y-gana",
    component: ScratchAndWinView,
  },
   {
    path: "/quiniela",
    name: "Quiniela",
    component: PoolsView,
  },
  {
    path: "/chat",
    name: "chat",
    component: ChatView,
  }, */
  {
    path: '/:pathMatch(.*)', component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/* GAURDS */
router.beforeEach((to, from, next) => {
/*  if (to.matched.some(route => route.meta.requireAuth)) {
    if(!store.state.log) {
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  } */

  if (to.matched.some(route => route.meta.logged)) {
    if(store.state.log) {
      next('/zona-premier')
    } else {
      next()
    }
  } else {
    next()
  } 


  if (to.matched.some(route => route.meta.noRequireMembership)) {
    if(store.state.membership) {
      next('/zona-premier')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;
