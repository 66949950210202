import { render, staticRenderFns } from "./BtnMembership.vue?vue&type=template&id=74d3fd12&scoped=true&"
import script from "./BtnMembership.vue?vue&type=script&lang=js&"
export * from "./BtnMembership.vue?vue&type=script&lang=js&"
import style0 from "./BtnMembership.vue?vue&type=style&index=0&id=74d3fd12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74d3fd12",
  null
  
)

export default component.exports