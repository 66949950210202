import { render, staticRenderFns } from "./TermsView.vue?vue&type=template&id=5fb5b6d7&scoped=true&"
import script from "./TermsView.vue?vue&type=script&lang=js&"
export * from "./TermsView.vue?vue&type=script&lang=js&"
import style0 from "./TermsView.vue?vue&type=style&index=0&id=5fb5b6d7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb5b6d7",
  null
  
)

export default component.exports